export const GenderL=[
    {
        "label": "Male",
        "value": "Male"
    },
    {
        "label": "Female",
        "value": "Female"
    },
    {
        "label": "Others",
        "value": "Others"
    }
]

export const stateL=[
    {
        "label": "Andaman And Nicobar Islands",
        "value": "Andaman And Nicobar Islands"
    },
    {
        "label": "Andhra Pradesh",
        "value": "Andhra Pradesh"
    },
    {
        "label": "Arunachal Pradesh",
        "value": "Arunachal Pradesh"
    },
    {
        "label": "Assam",
        "value": "Assam"
    },
    {
        "label": "Bihar",
        "value": "Bihar"
    },
    {
        "label": "Chandigarh",
        "value": "Chandigarh"
    },
    {
        "label": "Chhattisgarh",
        "value": "Chhattisgarh"
    },
    {
        "label": "Delhi",
        "value": "Delhi"
    },
    {
        "label": "Goa",
        "value": "Goa"
    },
    {
        "label": "Gujarat",
        "value": "Gujarat"
    },
    {
        "label": "Haryana",
        "value": "Haryana"
    },
    {
        "label": "Himachal pradesh",
        "value": "Himachal pradesh"
    },
    {
        "label": "Jammu And Kashmir",
        "value": "Jammu And Kashmir"
    },
    {
        "label": "Jharkhand",
        "value": "Jharkhand"
    },
    {
        "label": "Karnataka",
        "value": "Karnataka"
    },
    {
        "label": "Kerala",
        "value": "Kerala"
    },
    {
        "label": "Ladakh",
        "value": "Ladakh"
    },
    {
        "label": "Lakshadweep",
        "value": "Lakshadweep"
    },
    {
        "label": "Madhya pradesh",
        "value": "Madhya pradesh"
    },
    {
        "label": "Maharashtra",
        "value": "Maharashtra"
    },
    {
        "label": "Manipur",
        "value": "Manipur"
    },
    {
        "label": "Meghalaya",
        "value": "Meghalaya"
    },
    {
        "label": "Mizoram",
        "value": "Mizoram"
    },
    {
        "label": "Nagaland",
        "value": "Nagaland"
    },
    {
        "label": "Odisha",
        "value": "Odisha"
    },
    {
        "label": "Puducherry",
        "value": "Puducherry"
    },
    {
        "label": "Punjab",
        "value": "Punjab"
    },
    {
        "label": "Rajasthan",
        "value": "Rajasthan"
    },
    {
        "label": "Sikkim",
        "value": "Sikkim"
    },
    {
        "label": "Tamil nadu",
        "value": "Tamil nadu"
    },
    {
        "label": "Telangana",
        "value": "Telangana"
    },
    {
        "label": "The Dadra And Nagar Haveli And Daman And Diu",
        "value": "The Dadra And Nagar Haveli And Daman And Diu"
    },
    {
        "label": "Tripura",
        "value": "Tripura"
    },
    {
        "label": "Uttar pradesh",
        "value": "Uttar pradesh"
    },
    {
        "label": "Uttarakhand",
        "value": "Uttarakhand"
    },
    {
        "label": "West bengal",
        "value": "West bengal"
    }
]