export const skills = [
  {
    label:"Java",
    value:"Java"
  },
  {
    label:"JavaScript",
    value:"JavaScript"
  },
  {
    label:"C++",
    value:"C++"
  },
  {
    label:"HTML",
    value:"HTML"
  },
  {
    label:"Python",
    value:"Python"
  },
]

export const languageList = [
  {
    label:"Marathi",
    value:"Marathi"
  },
  {
    label:"Hindi",
    value:"Hindi"
  },
  {
    label:"English",
    value:"English"
  }
]

export const opportunities=[
  {
      "label": "Yes",
      "value": "Yes"
  },
  {
      "label": "No",
      "value": "No"
  }
]

export const talukas = {
  Kolhapur: [
    {
      label: "Karvir",
      value: "Karvir",
    },
    {
      label: "Panhala",
      value: "Panhala",
    },
    {
      label: "Shahuwadi",
      value: "Shahuwadi",
    },
    {
      label: "Kagal",
      value: "Kagal",
    },
    {
      label: "Hatkanangale",
      value: "Hatkanangale",
    },
    {
      label: "Shirol",
      value: "Shirol",
    },
    {
      label: "Radhanagari",
      value: "Radhanagari",
    },
    {
      label: "Gaganbawada",
      value: "Gaganbawada",
    },
    {
      label: "Bhudargad",
      value: "Bhudargad",
    },
    {
      label: "Gadhinglaj",
      value: "Gadhinglaj",
    },
    {
      label: "Chandgad",
      value: "Chandgad",
    },
    {
      label: "Ajra",
      value: "Ajra",
    },
  ],
  Sangli: [
    {
      label: "Miraj",
      value: "Miraj",
    },
    {
      label: "Kavathe-Mahankal",
      value: "Kavathe-Mahankal",
    },
    {
      label: "Tasgaon",
      value: "Tasgaon",
    },
    {
      label: "Jat",
      value: "Jat",
    },
    {
      label: "Walwa (Islampur)",
      value: "Walwa (Islampur)",
    },
    {
      label: "Shirala",
      value: "Shirala",
    },
    {
      label: "Khanapur-Vita",
      value: "Khanapur-Vita",
    },
    {
      label: "Atpadi",
      value: "Atpadi",
    },
    {
      label: "Palus",
      value: "Palus",
    },
    {
      label: "Kadegaon",
      value: "Kadegaon",
    },
  ],
  Satara: [
    {
      label: "Satara",
      value: "Satara",
    },
    {
      label: "Jaoli",
      value: "Jaoli",
    },
    {
      label: "Koregaon",
      value: "Koregaon",
    },
    {
      label: "Wai",
      value: "Wai",
    },
    {
      label: "Mahabaleshwar",
      value: "Mahabaleshwar",
    },
    {
      label: "Khandala",
      value: "Khandala",
    },
    {
      label: "Phaltan",
      value: "Phaltan",
    },
    {
      label: "Maan (Dahiwadi)",
      value: "Maan (Dahiwadi)",
    },
    {
      label: "Khatav (Vaduj)",
      value: "Khatav (Vaduj)",
    },
    {
      label: "Patan",
      value: "Patan",
    },
    {
      label: "Karad",
      value: "Karad",
    },
  ],
  Pune: [
    {
      label: "Pune City",
      value: "Pune City",
    },
    {
      label: "Haveli",
      value: "Haveli",
    },
    {
      label: "Khed (Rajgurunagar)",
      value: "Khed (Rajgurunagar)",
    },
    {
      label: "Junnar",
      value: "Junnar",
    },
    {
      label: "Ambegaon (Ghodegaon)",
      value: "Ambegaon (Ghodegaon)",
    },
    {
      label: "Maval (Vadgaon)",
      value: "Maval (Vadgaon)",
    },
    {
      label: "Mulshi (Paud)",
      value: "Mulshi (Paud)",
    },
    {
      label: "Shirur",
      value: "Shirur",
    },
    {
      label: "Purandhar (Saswad)",
      value: "Purandhar (Saswad)",
    },
    {
      label: "Velhe",
      value: "Velhe",
    },
    {
      label: "Bhor",
      value: "Bhor",
    },
    {
      label: "Baramati",
      value: "Baramati",
    },
    {
      label: "Indapur",
      value: "Indapur",
    },
    {
      label: "Daund",
      value: "Daund",
    },
  ],
  Ahmednagar: [
    {
      label: "Nagar",
      value: "Nagar",
    },
    {
      label: "Shevgaon",
      value: "Shevgaon",
    },
    {
      label: "Pathardi",
      value: "Pathardi",
    },
    {
      label: "Parner",
      value: "Parner",
    },
    {
      label: "Sangamner",
      value: "Sangamner",
    },
    {
      label: "Kopargaon",
      value: "Kopargaon",
    },
    {
      label: "Akole",
      value: "Akole",
    },
    {
      label: "Shrirampur",
      value: "Shrirampur",
    },
    {
      label: "Nevasa",
      value: "Nevasa",
    },
    {
      label: "Rahata",
      value: "Rahata",
    },
    {
      label: "Rahuri",
      value: "Rahuri",
    },
    {
      label: "Shrigonda",
      value: "Shrigonda",
    },
    {
      label: "Karjat",
      value: "Karjat",
    },
    {
      label: "Jamkhed",
      value: "Jamkhed",
    },
  ],
  Solapur: [
    {
      label: "Solapur North",
      value: "Solapur North",
    },
    {
      label: "Barshi",
      value: "Barshi",
    },
    {
      label: "Solapur South",
      value: "Solapur South",
    },
    {
      label: "Akkalkot",
      value: "Akkalkot",
    },
    {
      label: "Madha",
      value: "Madha",
    },
    {
      label: "Karmala",
      value: "Karmala",
    },
    {
      label: "Pandharpur",
      value: "Pandharpur",
    },
    {
      label: "Mohol",
      value: "Mohol",
    },
    {
      label: "Malshiras",
      value: "Malshiras",
    },
    {
      label: "Sangole",
      value: "Sangole",
    },
    {
      label: "Mangalvedhe",
      value: "Mangalvedhe",
    },
  ],
  Osmanabad: [
    {
      label: "Osmanabad",
      value: "Osmanabad",
    },
    {
      label: "Tuljapur",
      value: "Tuljapur",
    },
    {
      label: "Bhum",
      value: "Bhum",
    },
    {
      label: "Paranda",
      value: "Paranda",
    },
    {
      label: "Washi",
      value: "Washi",
    },
    {
      label: "Kalamb",
      value: "Kalamb",
    },
    {
      label: "Lohara",
      value: "Lohara",
    },
    {
      label: "Umarga",
      value: "Umarga",
    },
  ],
  Latur: [
    {
      label: "Latur",
      value: "Latur",
    },
    {
      label: "Renapur",
      value: "Renapur",
    },
    {
      label: "Ahmedpur",
      value: "Ahmedpur",
    },
    {
      label: "Jalkot",
      value: "Jalkot",
    },
    {
      label: "Chakur",
      value: "Chakur",
    },
    {
      label: "Shirur Anantpal",
      value: "Shirur Anantpal",
    },
    {
      label: "Ausa",
      value: "Ausa",
    },
    {
      label: "Nilanga",
      value: "Nilanga",
    },
    {
      label: "Deoni",
      value: "Deoni",
    },
    {
      label: "Udgir",
      value: "Udgir",
    },
  ],
  Beed: [
    {
      label: "Beed",
      value: "Beed",
    },
    {
      label: "Ashti",
      value: "Ashti",
    },
    {
      label: "Patoda",
      value: "Patoda",
    },
    {
      label: "Shirur-Kasar",
      value: "Shirur-Kasar",
    },
    {
      label: "Georai",
      value: "Georai",
    },
    {
      label: "Majalgaon",
      value: "Majalgaon",
    },
    {
      label: "Wadwani",
      value: "Wadwani",
    },
    {
      label: "Kaij",
      value: "Kaij",
    },
    {
      label: "Dharur",
      value: "Dharur",
    },
    {
      label: "Parli",
      value: "Parli",
    },
    {
      label: "Ambejogai",
      value: "Ambejogai",
    },
  ],
  Aurangabad: [
    {
      label: "Aurangabad",
      value: "Aurangabad",
    },
    {
      label: "Kannad",
      value: "Kannad",
    },
    {
      label: "Soegaon",
      value: "Soegaon",
    },
    {
      label: "Sillod",
      value: "Sillod",
    },
    {
      label: "Phulambri",
      value: "Phulambri",
    },
    {
      label: "Khuldabad",
      value: "Khuldabad",
    },
    {
      label: "Vaijapur",
      value: "Vaijapur",
    },
    {
      label: "Gangapur",
      value: "Gangapur",
    },
    {
      label: "Paithan",
      value: "Paithan",
    },
  ],
  Jalna: [
    {
      label: "Jalna",
      value: "Jalna",
    },
    {
      label: "Bhokardan",
      value: "Bhokardan",
    },
    {
      label: "Jafrabad",
      value: "Jafrabad",
    },
    {
      label: "Badnapur",
      value: "Badnapur",
    },
    {
      label: "Ambad",
      value: "Ambad",
    },
    {
      label: "Ghansawangi",
      value: "Ghansawangi",
    },
    {
      label: "Partur",
      value: "Partur",
    },
    {
      label: "Mantha",
      value: "Mantha",
    },
  ],
  Parbhani: [
    {
      label: "Parbhani",
      value: "Parbhani",
    },
    {
      label: "Sonpeth",
      value: "Sonpeth",
    },
    {
      label: "Gangakhed",
      value: "Gangakhed",
    },
    {
      label: "Palam",
      value: "Palam",
    },
    {
      label: "Purna",
      value: "Purna",
    },
    {
      label: "Sailu",
      value: "Sailu",
    },
    {
      label: "Jintur",
      value: "Jintur",
    },
    {
      label: "Manwath",
      value: "Manwath",
    },
    {
      label: "Pathri",
      value: "Pathri",
    },
  ],
  Hingoli: [
    {
      label: "Hingoli",
      value: "Hingoli",
    },
    {
      label: "Sengaon",
      value: "Sengaon",
    },
    {
      label: "Kalamnuri",
      value: "Kalamnuri",
    },
    {
      label: "Basmath",
      value: "Basmath",
    },
    {
      label: "Aundha Nagnath",
      value: "Aundha Nagnath",
    },
  ],
  Nanded: [
    {
      label: "Nanded",
      value: "Nanded",
    },
    {
      label: "Ardhapur",
      value: "Ardhapur",
    },
    {
      label: "Mudkhed",
      value: "Mudkhed",
    },
    {
      label: "Bhokar",
      value: "Bhokar",
    },
    {
      label: "Umri",
      value: "Umri",
    },
    {
      label: "Loha",
      value: "Loha",
    },
    {
      label: "Kandhar",
      value: "Kandhar",
    },
    {
      label: "Kinwat",
      value: "Kinwat",
    },
    {
      label: "Himayatnagar",
      value: "Himayatnagar",
    },
    {
      label: "Hadgaon",
      value: "Hadgaon",
    },
    {
      label: "Mahur",
      value: "Mahur",
    },
    {
      label: "Deglur",
      value: "Deglur",
    },
    {
      label: "Mukhed",
      value: "Mukhed",
    },
    {
      label: "Dharmabad",
      value: "Dharmabad",
    },
    {
      label: "Biloli",
      value: "Biloli",
    },
    {
      label: "Naigaon (Khairgaon)",
      value: "Naigaon (Khairgaon)",
    },
  ],
  Yavatmal: [
    {
      label: "Yavatmal",
      value: "Yavatmal",
    },
    {
      label: "Arni",
      value: "Arni",
    },
    {
      label: "Babhulgaon",
      value: "Babhulgaon",
    },
    {
      label: "Kalamb",
      value: "Kalamb",
    },
    {
      label: "Darwha",
      value: "Darwha",
    },
    {
      label: "Digras",
      value: "Digras",
    },
    {
      label: "Ner",
      value: "Ner",
    },
    {
      label: "Pusad",
      value: "Pusad",
    },
    {
      label: "Umarkhed",
      value: "Umarkhed",
    },
    {
      label: "Mahagaon",
      value: "Mahagaon",
    },
    {
      label: "Kelapur (Pandharkawada)",
      value: "Kelapur (Pandharkawada)",
    },
    {
      label: "Ralegaon",
      value: "Ralegaon",
    },
    {
      label: "Ghatanji",
      value: "Ghatanji",
    },
    {
      label: "Wani",
      value: "Wani",
    },
    {
      label: "Maregaon",
      value: "Maregaon",
    },
    {
      label: "Zari Jamani",
      value: "Zari Jamani",
    },
  ],
  Chandrapur: [
    {
      label: "Chandrapur",
      value: "Yavatmal",
    },
    {
      label: "Saoli",
      value: "Arni",
    },
    {
      label: "Mul",
      value: "Babhulgaon",
    },
    {
      label: "Ballarpur",
      value: "Kalamb",
    },
    {
      label: "Pombhurna",
      value: "Darwha",
    },
    {
      label: "Gondpimpri",
      value: "Digras",
    },
    {
      label: "Warora",
      value: "Ner",
    },
    {
      label: "Chimur",
      value: "Pusad",
    },
    {
      label: "Bhadravati",
      value: "Umarkhed",
    },
    {
      label: "Bramhapuri",
      value: "Mahagaon",
    },
    {
      label: "Nagbhid",
      value: "Kelapur (Pandharkawada)",
    },
    {
      label: "Sindewahi",
      value: "Ralegaon",
    },
    {
      label: "Rajura",
      value: "Ghatanji",
    },
    {
      label: "Korpana",
      value: "Wani",
    },
    {
      label: "Jivati",
      value: "Maregaon",
    },
  ],
  Gadchiroli: [
    {
      label: "Gadchiroli",
      value: "Gadchiroli",
    },
    {
      label: "Dhanora",
      value: "Dhanora",
    },
    {
      label: "Chamorshi",
      value: "Chamorshi",
    },
    {
      label: "Mulchera",
      value: "Mulchera",
    },
    {
      label: "Desaiganj (Vadasa)",
      value: "Desaiganj (Vadasa)",
    },
    {
      label: "Armori",
      value: "Armori",
    },
    {
      label: "Kurkheda",
      value: "Kurkheda",
    },
    {
      label: "Korchi",
      value: "Korchi",
    },
    {
      label: "Aheri",
      value: "Aheri",
    },
    {
      label: "Bhamragad",
      value: "Bhamragad",
    },
    {
      label: "Sironcha",
      value: "Sironcha",
    },
  ],
  Gondia: [
    {
      label: "Gondia",
      value: "Gondia",
    },
    {
      label: "Tirora",
      value: "Tirora",
    },
    {
      label: "Goregaon",
      value: "Goregaon",
    },
    {
      label: "Arjuni Morgaon",
      value: "Arjuni Morgaon",
    },
    {
      label: "Amgaon",
      value: "Amgaon",
    },
    {
      label: "Salekasa",
      value: "Salekasa",
    },
    {
      label: "Sadak Arjuni",
      value: "Sadak Arjuni",
    },
    {
      label: "Deori",
      value: "Deori",
    },
  ],
  Bhandara: [
    {
      label: "Bhandara",
      value: "Bhandara",
    },
    {
      label: "Tumsar",
      value: "Tumsar",
    },
    {
      label: "Pauni",
      value: "Pauni",
    },
    {
      label: "Mohadi",
      value: "Mohadi",
    },
    {
      label: "Sakoli",
      value: "Sakoli",
    },
    {
      label: "Lakhni",
      value: "Lakhni",
    },
    {
      label: "Lakhandur",
      value: "Lakhandur",
    },
  ],
  Nagpur: [
    {
      label: "Nagpur (Urban)",
      value: "Nagpur (Urban)",
    },
    {
      label: "Nagpur (Rural)",
      value: "Nagpur (Rural)",
    },
    {
      label: "Kamptee",
      value: "Kamptee",
    },
    {
      label: "Hingna",
      value: "Hingna",
    },
    {
      label: "Katol",
      value: "Katol",
    },
    {
      label: "Narkhed",
      value: "Narkhed",
    },
    {
      label: "Savner",
      value: "Savner",
    },
    {
      label: "Kalameshwar",
      value: "Kalameshwar",
    },
    {
      label: "Ramtek",
      value: "Ramtek",
    },
    {
      label: "Mouda",
      value: "Mouda",
    },
    {
      label: "Parseoni",
      value: "Parseoni",
    },
    {
      label: "Umred",
      value: "Umred",
    },
    {
      label: "Kuhi",
      value: "Kuhi",
    },
    {
      label: "Bhiwapur",
      value: "Bhiwapur",
    },
  ],
  Wardha: [
    {
      label: "Wardha",
      value: "Wardha",
    },
    {
      label: "Deoli",
      value: "Deoli",
    },
    {
      label: "Seloo",
      value: "Seloo",
    },
    {
      label: "Arvi",
      value: "Arvi",
    },
    {
      label: "Ashti",
      value: "Ashti",
    },
    {
      label: "Karanja",
      value: "Karanja",
    },
    {
      label: "Hinganghat",
      value: "Hinganghat",
    },
    {
      label: "Samudrapur",
      value: "Samudrapur",
    },
  ],
  Amravati: [
    {
      label: "Amravati",
      value: "Amravati",
    },
    {
      label: "Bhatukali",
      value: "Bhatukali",
    },
    {
      label: "Nandgaon Khandeshwar",
      value: "Nandgaon Khandeshwar",
    },
    {
      label: "Dharni (Amravati)",
      value: "Dharni (Amravati)",
    },
    {
      label: "Chikhaldara",
      value: "Chikhaldara",
    },
    {
      label: "Achalpur",
      value: "Achalpur",
    },
    {
      label: "Chandurbazar",
      value: "Chandurbazar",
    },
    {
      label: "Morshi",
      value: "Morshi",
    },
    {
      label: "Warud",
      value: "Warud",
    },
    {
      label: "Daryapur",
      value: "Daryapur",
    },
    {
      label: "Anjangaon-Surji",
      value: "Anjangaon-Surji",
    },
    {
      label: "Chandur",
      value: "Chandur",
    },
    {
      label: "Dhamangaon",
      value: "Dhamangaon",
    },
    {
      label: "Tiosa",
      value: "Tiosa",
    },
  ],
  Washim: [
    {
      label: "Washim",
      value: "Washim",
    },
    {
      label: "Malegaon",
      value: "Malegaon",
    },
    {
      label: "Risod",
      value: "Risod",
    },
    {
      label: "Mangrulpir",
      value: "Mangrulpir",
    },
    {
      label: "Karanja",
      value: "Karanja",
    },
    {
      label: "Manora",
      value: "Manora",
    },
  ],
  Akola: [
    {
      label: "Akola",
      value: "Akola",
    },
    {
      label: "Akot",
      value: "Akot",
    },
    {
      label: "Telhara",
      value: "Telhara",
    },
    {
      label: "Balapur",
      value: "Balapur",
    },
    {
      label: "Patur",
      value: "Patur",
    },
    {
      label: "Murtajapur",
      value: "Murtajapur",
    },
    {
      label: "Barshitakli",
      value: "Barshitakli",
    },
  ],
  Buldhana: [
    {
      label: "Buldhana",
      value: "Buldhana",
    },
    {
      label: "Chikhli",
      value: "Chikhli",
    },
    {
      label: "Deulgaon Raja",
      value: "Deulgaon Raja",
    },
    {
      label: "Jalgaon Jamod",
      value: "Jalgaon Jamod",
    },
    {
      label: "Sangrampur",
      value: "Sangrampur",
    },
    {
      label: "Malkapur",
      value: "Malkapur",
    },
    {
      label: "Motala",
      value: "Motala",
    },
    {
      label: "Nandura",
      value: "Nandura",
    },
    {
      label: "Khamgaon",
      value: "Khamgaon",
    },
    {
      label: "Shegaon",
      value: "Shegaon",
    },
    {
      label: "Mehkar",
      value: "Mehkar",
    },
    {
      label: "Sindkhed Raja",
      value: "Sindkhed Raja",
    },
    {
      label: "Lonar",
      value: "Lonar",
    },
  ],
  Jalgaon: [
    {
      label: "Jalgaon",
      value: "Jalgaon",
    },
    {
      label: "Jamner",
      value: "Jamner",
    },
    {
      label: "Erandol",
      value: "Erandol",
    },
    {
      label: "Dharangaon",
      value: "Dharangaon",
    },
    {
      label: "Bhusawal",
      value: "Bhusawal",
    },
    {
      label: "Raver",
      value: "Raver",
    },
    {
      label: "Muktainagar (Edalabad)",
      value: "Muktainagar (Edalabad)",
    },
    {
      label: "Bodwad",
      value: "Bodwad",
    },
    {
      label: "Yawal",
      value: "Yawal",
    },
    {
      label: "Amalner",
      value: "Amalner",
    },
    {
      label: "Parola",
      value: "Parola",
    },
    {
      label: "Chopda",
      value: "Chopda",
    },
    {
      label: "Pachora",
      value: "Pachora",
    },
    {
      label: "Bhadgaon",
      value: "Bhadgaon",
    },
    {
      label: "Chalisgaon",
      value: "Chalisgaon",
    },
  ],
  Dhule: [
    {
      label: "Dhule",
      value: "Dhule",
    },
    {
      label: "Sakri",
      value: "Sakri",
    },
    {
      label: "Sindkheda",
      value: "Sindkheda",
    },
    {
      label: "Shirpur",
      value: "Shirpur",
    },
  ],
  Nandurbar: [
    {
      label: "Nandurbar",
      value: "Nandurbar",
    },
    {
      label: "Navapur",
      value: "Navapur",
    },
    {
      label: "Shahada",
      value: "Shahada",
    },
    {
      label: "Taloda",
      value: "Taloda",
    },
    {
      label: "Akkalkuwa",
      value: "Akkalkuwa",
    },
    {
      label: "Akrani (Dhadgaon)",
      value: "Akrani (Dhadgaon)",
    },
  ],
  Nashik: [
    {
      label: "Nashik",
      value: "Nashik",
    },
    {
      label: "Igatpuri",
      value: "Igatpuri",
    },
    {
      label: "Dindori",
      value: "Dindori",
    },
    {
      label: "Peth",
      value: "Peth",
    },
    {
      label: "Trimbakeshwar",
      value: "Trimbakeshwar",
    },
    {
      label: "Kalwan",
      value: "Kalwan",
    },
    {
      label: "Deola",
      value: "Deola",
    },
    {
      label: "Surgana",
      value: "Surgana",
    },
    {
      label: "Baglan (Satana)",
      value: "Baglan (Satana)",
    },
    {
      label: "Malegaon",
      value: "Malegaon",
    },
    {
      label: "Nandgaon",
      value: "Nandgaon",
    },
    {
      label: "Chandwad",
      value: "Chandwad",
    },
    {
      label: "Niphad",
      value: "Niphad",
    },
    {
      label: "Sinnar",
      value: "Sinnar",
    },
    {
      label: "Yeola",
      value: "Yeola",
    },
  ],
  Paghar: [
    {
      label: "Palghar",
      value: "Palghar",
    },
    {
      label: "Vasai",
      value: "Vasai",
    },
    {
      label: "Dahanu",
      value: "Dahanu",
    },
    {
      label: "Talasari",
      value: "Talasari",
    },
    {
      label: "Jawhar",
      value: "Jawhar",
    },
    {
      label: "Mokhada",
      value: "Mokhada",
    },
    {
      label: "Vada",
      value: "Vada",
    },
    {
      label: "Vikramgad",
      value: "Vikramgad",
    },
  ],
  Thane: [
    {
      label: "Thane",
      value: "Thane",
    },
    {
      label: "Kalyan",
      value: "Kalyan",
    },
    {
      label: "Murbad",
      value: "Murbad",
    },
    {
      label: "Bhiwandi",
      value: "Bhiwandi",
    },
    {
      label: "Shahapur",
      value: "Shahapur",
    },
    {
      label: "Ulhasnagar",
      value: "Ulhasnagar",
    },
    {
      label: "Ambarnath",
      value: "Ambarnath",
    },
  ],
  Mumbai: [
    {
      value: "Acharya Atre Nagar",
      label: "Acharya Atre Nagar",
    },
    {
      value: "Airoli",
      label: "Airoli",
    },
    {
      value: "Airport Road",
      label: "Airport Road",
    },
    {
      value: "Ambedkar Nagar",
      label: "Ambedkar Nagar",
    },
    {
      value: "Ambernath",
      label: "Ambernath",
    },
    {
      value: "Ambivili",
      label: "Ambivili",
    },
    {
      value: "Andheri",
      label: "Andheri",
    },
    {
      value: "Antop Hill",
      label: "Antop Hill",
    },
    {
      value: "Asalpha",
      label: "Asalpha",
    },
    {
      value: "Asangaon",
      label: "Asangaon",
    },
    {
      value: "Atgaon",
      label: "Atgaon",
    },
    {
      value: "Azad Nagar",
      label: "Azad Nagar",
    },
    {
      value: "Badlapur",
      label: "Badlapur",
    },
    {
      value: "Bandra",
      label: "Bandra",
    },
    {
      value: "Belapur CBD",
      label: "Belapur CBD",
    },
    {
      value: "Bhakti Park",
      label: "Bhakti Park",
    },
    {
      value: "Bhandup",
      label: "Bhandup",
    },
    {
      value: "Bharat Petroleum",
      label: "Bharat Petroleum",
    },
    {
      value: "Bhayander",
      label: "Bhayander",
    },
    {
      value: "Bhivpuri",
      label: "Bhivpuri",
    },
    {
      value: "Borivali",
      label: "Borivali",
    },
    {
      value: "Byculla",
      label: "Byculla",
    },
    {
      value: "Chakala",
      label: "Chakala",
    },
    {
      value: "Charni Rd",
      label: "Charni Rd",
    },
    {
      value: "Chembur",
      label: "Chembur",
    },
    {
      value: "Chinchpokli",
      label: "Chinchpokli",
    },
    {
      value: "Chunabhatti",
      label: "Chunabhatti",
    },
    {
      value: "Churchgate",
      label: "Churchgate",
    },
    {
      value: "Cotton Green",
      label: "Cotton Green",
    },
    {
      value: "Currey Road",
      label: "Currey Road",
    },
    {
      value: "Dadar",
      label: "Dadar",
    },
    {
      value: "Dadar (East)",
      label: "Dadar (East)",
    },
    {
      value: "Dahisar",
      label: "Dahisar",
    },
    {
      value: "Diva",
      label: "Diva",
    },
    {
      value: "DN Nagar",
      label: "DN Nagar",
    },
    {
      value: "Dockyard Road",
      label: "Dockyard Road",
    },
    {
      value: "Dolavi",
      label: "Dolavi",
    },
    {
      value: "Dombivili",
      label: "Dombivili",
    },
    {
      value: "Elphinstone rd/Prabhadevi",
      label: "Elphinstone rd/Prabhadevi",
    },
    {
      value: "Fertilizer Township",
      label: "Fertilizer Township",
    },
    {
      value: "Ghansoli",
      label: "Ghansoli",
    },
    {
      value: "Ghatkopar",
      label: "Ghatkopar",
    },
    {
      value: "Goregaon",
      label: "Goregaon",
    },
    {
      value: "Govandi",
      label: "Govandi",
    },
    {
      value: "Grant Rd",
      label: "Grant Rd",
    },
    {
      value: "GTB Nagar",
      label: "GTB Nagar",
    },
    {
      value: "Jagruti Nagar",
      label: "Jagruti Nagar",
    },
    {
      value: "Jogeshwari",
      label: "Jogeshwari",
    },
    {
      value: "Juinagar",
      label: "Juinagar",
    },
    {
      value: "Kalwa",
      label: "Kalwa",
    },
    {
      value: "Kalyan",
      label: "Kalyan",
    },
    {
      value: "Kandivali",
      label: "Kandivali",
    },
    {
      value: "Kanjurmarg",
      label: "Kanjurmarg",
    },
    {
      value: "Karjat",
      label: "Karjat",
    },
    {
      value: "Kasara",
      label: "Kasara",
    },
    {
      value: "Kelavi",
      label: "Kelavi",
    },
    {
      value: "Khadavli",
      label: "Khadavli",
    },
    {
      value: "Khandeshwar",
      label: "Khandeshwar",
    },
    {
      value: "Khar Rd",
      label: "Khar Rd",
    },
    {
      value: "Khar Road",
      label: "Khar Road",
    },
    {
      value: "Khardi",
      label: "Khardi",
    },
    {
      value: "Kharghar",
      label: "Kharghar",
    },
    {
      value: "Khopoli",
      label: "Khopoli",
    },
    {
      value: "Kings Circle",
      label: "Kings Circle",
    },
    {
      value: "Kopar",
      label: "Kopar",
    },
    {
      value: "Koparkhairne",
      label: "Koparkhairne",
    },
    {
      value: "Kurla",
      label: "Kurla",
    },
    {
      value: "Lower Parel",
      label: "Lower Parel",
    },
    {
      value: "Lowjee",
      label: "Lowjee",
    },
    {
      value: "Mahalakshmi",
      label: "Mahalakshmi",
    },
    {
      value: "Mahim",
      label: "Mahim",
    },
    {
      value: "Mahim Jn",
      label: "Mahim Jn",
    },
    {
      value: "Malad",
      label: "Malad",
    },
    {
      value: "Manasarovar",
      label: "Manasarovar",
    },
    {
      value: "Mankhurd",
      label: "Mankhurd",
    },
    {
      value: "Mansarovar",
      label: "Mansarovar",
    },
    {
      value: "Marine Lines",
      label: "Marine Lines",
    },
    {
      value: "Marol",
      label: "Marol",
    },
    {
      value: "Masjid",
      label: "Masjid",
    },
    {
      value: "Matunga",
      label: "Matunga",
    },
    {
      value: "Matunga Rd",
      label: "Matunga Rd",
    },
    {
      value: "Mint Colony",
      label: "Mint Colony",
    },
    {
      value: "Mira Rd",
      label: "Mira Rd",
    },
    {
      value: "Mulund",
      label: "Mulund",
    },
    {
      value: "Mumbai",
      label: "Mumbai",
    },
    {
      value: "Mumbai Central",
      label: "Mumbai Central",
    },
    {
      value: "Mumbai CST",
      label: "Mumbai CST",
    },
    {
      value: "Mumbai Metro",
      label: "Mumbai Metro",
    },
    {
      value: "Mumbai Monorail",
      label: "Mumbai Monorail",
    },
    {
      value: "Mumbai_CST",
      label: "Mumbai_CST",
    },
    {
      value: "Mumbra",
      label: "Mumbra",
    },
    {
      value: "Mysore Colony",
      label: "Mysore Colony",
    },
    {
      value: "Nahur",
      label: "Nahur",
    },
    {
      value: "Naigaon",
      label: "Naigaon",
    },
    {
      value: "Nalla Sopara",
      label: "Nalla Sopara",
    },
    {
      value: "Neral",
      label: "Neral",
    },
    {
      value: "Nerul",
      label: "Nerul",
    },
    {
      value: "Palasdari",
      label: "Palasdari",
    },
    {
      value: "Panvel",
      label: "Panvel",
    },
    {
      value: "Parel",
      label: "Parel",
    },
    {
      value: "Rabale",
      label: "Rabale",
    },
    {
      value: "Ram Mandir",
      label: "Ram Mandir",
    },
    {
      value: "Reay Road",
      label: "Reay Road",
    },
    {
      value: "Saki Naka",
      label: "Saki Naka",
    },
    {
      value: "Sandhurst Road",
      label: "Sandhurst Road",
    },
    {
      value: "Sandurst Road",
      label: "Sandurst Road",
    },
    {
      value: "Sanpada",
      label: "Sanpada",
    },
    {
      value: "Sant Gadge Maharaj Chowk",
      label: "Sant Gadge Maharaj Chowk",
    },
    {
      value: "Santa Cruz",
      label: "Santa Cruz",
    },
    {
      value: "Santacruz",
      label: "Santacruz",
    },
    {
      value: "Seawood Darave",
      label: "Seawood Darave",
    },
    {
      value: "Sewri",
      label: "Sewri",
    },
    {
      value: "Shahad",
      label: "Shahad",
    },
    {
      value: "Shelu",
      label: "Shelu",
    },
    {
      value: "Sion",
      label: "Sion",
    },
    {
      value: "Thakurli",
      label: "Thakurli",
    },
    {
      value: "Thane",
      label: "Thane",
    },
    {
      value: "Tilaknagar",
      label: "Tilaknagar",
    },
    {
      value: "Titwala",
      label: "Titwala",
    },
    {
      value: "Turbhe",
      label: "Turbhe",
    },
    {
      value: "Ulhasnagar",
      label: "Ulhasnagar",
    },
    {
      value: "Vangani",
      label: "Vangani",
    },
    {
      value: "Vasai Rd",
      label: "Vasai Rd",
    },
    {
      value: "Vashi",
      label: "Vashi",
    },
    {
      value: "Vasind",
      label: "Vasind",
    },
    {
      value: "Versova",
      label: "Versova",
    },
    {
      value: "Vidhyavihar",
      label: "Vidhyavihar",
    },
    {
      value: "Vikhroli",
      label: "Vikhroli",
    },
    {
      value: "Vile Parle",
      label: "Vile Parle",
    },
    {
      value: "Virar",
      label: "Virar",
    },
    {
      value: "Vithalwadi",
      label: "Vithalwadi",
    },
    {
      value: "VNP and RC Marg Junction",
      label: "VNP and RC Marg Junction",
    },
    {
      value: "Wadala Bridge",
      label: "Wadala Bridge",
    },
    {
      value: "Wadala Depot",
      label: "Wadala Depot",
    },
    {
      value: "Wadala Rd",
      label: "Wadala Rd",
    },
    {
      value: "Western Express Highway",
      label: "Western Express Highway",
    },
  ],
  Raigad: [
    {
      label: "Pen",
      value: "Pen",
    },
    {
      label: "Alibaug",
      value: "Alibaug",
    },
    {
      label: "Murud",
      value: "Murud",
    },
    {
      label: "Panvel",
      value: "Panvel",
    },
    {
      label: "Uran",
      value: "Uran",
    },
    {
      label: "Karjat (Matheran)",
      value: "Karjat (Matheran)",
    },
    {
      label: "Khalapur",
      value: "Khalapur",
    },
    {
      label: "Mangaon",
      value: "Mangaon",
    },
    {
      label: "Tala",
      value: "Tala",
    },
    {
      label: "Roha",
      value: "Roha",
    },
    {
      label: "Sudhagad (Pali)",
      value: "Sudhagad (Pali)",
    },
    {
      label: "Mahad",
      value: "Mahad",
    },
    {
      label: "Poladpur",
      value: "Poladpur",
    },
    {
      label: "Shrivardhan",
      value: "Shrivardhan",
    },
    {
      label: "Mhasla",
      value: "Mhasla",
    },
  ],
  Ratnagiri: [
    {
      label: "Ratnagiri",
      value: "Ratnagiri",
    },
    {
      label: "Sangameshwar (Deorukh)",
      value: "Sangameshwar (Deorukh)",
    },
    {
      label: "Lanja",
      value: "Lanja",
    },
    {
      label: "Rajapur",
      value: "Rajapur",
    },
    {
      label: "Chiplun",
      value: "Chiplun",
    },
    {
      label: "Guhagar",
      value: "Guhagar",
    },
    {
      label: "Dapoli",
      value: "Dapoli",
    },
    {
      label: "Mandangad",
      value: "Mandangad",
    },
    {
      label: "Khed",
      value: "Khed",
    },
  ],
  Sindhudurg: [
    {
      label: "Kankavli",
      value: "Kankavli",
    },
    {
      label: "Vaibhavwadi",
      value: "Vaibhavwadi",
    },
    {
      label: "Devgad",
      value: "Devgad",
    },
    {
      label: "Malwan",
      value: "Malwan",
    },
    {
      label: "Sawantwadi",
      value: "Sawantwadi",
    },
    {
      label: "Kudal",
      value: "Kudal",
    },
    {
      label: "Vengurla",
      value: "Vengurla",
    },
    {
      label: "Dodamarg (Kasal)",
      value: "Dodamarg (Kasal)",
    },
  ],
};

export const job_location = [
  // {
  //   label: "Colaba [03-Dec-2022]",
  //   value: "Colaba [03-Dec-2022]",
  // },
  {
    label: "Byculla [10-Dec-2022]",
    value: "Byculla [10-Dec-2022]",
  },
  {
    label: "Shivdi [17-Dec-2022]",
    value: "Shivdi [17-Dec-2022]",
  },
  {
    label: "Worli [24-Dec-2022]",
    value: "Worli [24-Dec-2022]",
  },
  {
    label: "Mumbadevi [07-Jan-2023]",
    value: "Mumbadevi [07-Jan-2023]",
  },

  // {
  //   label: "Sion Koliwada",
  //   value: "Sion Koliwada",
  // },
  // {
  //   label: "Goregaon",
  //   value: "Goregaon",
  // },

  // {
  //   label: "Colaba",
  //   value: "Colaba",
  // },
  // {
  //   label: "Ghatkopar (W.)",
  //   value: "Ghatkopar (W.)",
  // },

  // {
  //   label: "Mahim",
  //   value: "Mahim",
  // },

  // {
  //   label: "Vileparle",
  //   value: "Vileparle",
  // },

  // {
  //   label: "Andheri (W) [28-Nov-2022]",
  //   value: "Andheri (W) [28-Nov-2022]",
  // },
  // {
  //   label: "Ghatkopar (East) [2-Dec-2022]",
  //   value: "Ghatkopar (East) [2-Dec-2022]",
  // },
  // {
  //   label: "Kandivali (East) [08-Dec-2022]",
  //   value: "Kandivali (East) [08-Dec-2022]",
  // },
  // {
  //   label: "Chembur [12-Dec-2022]",
  //   value: "Chembur [12-Dec-2022]",
  // },

  // {
  //   label: "Bhandup (W.) [17-Dec-2022]",
  //   value: "Bhandup (W.) [17-Dec-2022]",
  // },
  // {
  //   label: "Malad (W.) [23-Dec-2022]",
  //   value: "Malad (W.) [23-Dec-2022]",
  // },
  // {
  //   label: "Bandra (East) [29-Dec-2022]",
  //   value: "Bandra (East) [29-Dec-2022]",
  // },
  // {
  //   label: "Dahisar [12-Jan-2023]",
  //   value: "Dahisar [12-Jan-2023]",
  // },

  // {
  //   label: "Kalina",
  //   value: "Kalina",
  // },
  // {
  //   label: "Dindoshi",
  //   value: "Dindoshi",
  // },
  // {
  //   label: "Shivdi",
  //   value: "Shivdi",
  // },
  // {
  //   label: "Charkop",
  //   value: "Charkop",
  // },

  // {
  //   label: "Bandra (West)",
  //   value: "Bandra (West)",
  // },
  // {
  //   label: "Versova",
  //   value: "Versova",
  // },
  // {
  //   label: "Wadala",
  //   value: "Wadala",
  // },
  // {
  //   label: "Andheri (East)",
  //   value: "Andheri (East)",
  // },
  // {
  //   label: "Chandivali",
  //   value: "Chandivali",
  // },
  // {
  //   label: "Vikhroli",
  //   value: "Vikhroli",
  // },
  // {
  //   label: "Baikal",
  //   value: "Baikal",
  // },
  // {
  //   label: "Jogeshwari (East)",
  //   value: "Jogeshwari (East)",
  // },
  // {
  //   label: "Dharavi",
  //   value: "Dharavi",
  // },
  // {
  //   label: "Anushakti Nagar",
  //   value: "Anushakti Nagar",
  // },
  // {
  //   label: "Mankhurd Shivaji Nagar",
  //   value: "Mankhurd Shivaji Nagar",
  // },
  // {
  //   label: "Mumbadevi",
  //   value: "Mumbadevi",
  // },
];

export const sector = [
  {
    label: "Textile and Clothing",
    value: "Textile and Clothing",
  },
  {
    label: "Building and Construction",
    value: "Building and Construction",
  },
  {
    label: "Automobile and Transportation",
    value: "Automobile and Transportation",
  },
  {
    label: "Organized and Unorganized Retail",
    value: "Organized and Unorganized Retail",
  },
  {
    label: "Gems and Jewellery",
    value: "Gems and Jewellery",
  },
  {
    label: "IT &amp; Computers",
    value: "IT &amp; Computers",
  },
  {
    label: "Furniture and Furnishing",
    value: "Furniture and Furnishing",
  },
  {
    label: "Healthcare",
    value: "Healthcare",
  },
  {
    label: "Leather and Leather Goods",
    value: "Leather and Leather Goods",
  },
  {
    label: "Tourism and Hospitality",
    value: "Tourism and Hospitality",
  },
  {
    label: "Agriculture",
    value: "Agriculture",
  },
  {
    label: "Mining",
    value: "Mining",
  },
  {
    label: "Households",
    value: "Households",
  },
  {
    label: "Media and Entertainment",
    value: "Media and Entertainment",
  },
];

export const priorities = [
  { label: "Project Effected", value: "Project Affected" },
  { label: "Earthquake Effected", value: "Earthquake Effected" },
  {
    label: "Dependent Of Freedom Fighter",
    value: "Dependent Of Freedom Fighter",
  },
  { label: "Other", value: "Other" },
];

export const gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];

export const experience = [
  { label: "0-1 Year", value: "0-1" },
  { label: "1-3 Years", value: "1-3" },
  { label: "3-5 Years", value: "3-5" },
  { label: "5-10 Years", value: "5-10" },
  { label: "10-20 Years", value: "10-20" },
  { label: "20+ Years", value: "20+" },
];

export const serviceType = [
  { label: "Army", value: "Army" },
  { label: "Navy", value: "Navy" },
  { label: "Air-Force", value: "Air-Force" },
];

export const rank = [
  { label: "JCO", value: "JCO" },
  { label: "NCO", value: "NCO" },
  { label: "OLR", value: "OLR" },
];

export const partTime = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const maritalStatus = [
  { label: "Unmarried", value: "Unmerried" },
  { label: "Married", value: "Married" },
  { label: "Widow", value: "Widow" },
  { label: "Widower", value: "Widower" },
  { label: "Divorced", value: "Divorced" },
];

export const taluka = [
  { label: "Andheri", value: "Andheri" },
  { label: "Borivali", value: "Borivali" },
  { label: "Kurla", value: "Kurla" },
  { label: "Mumbai City", value: "Mumbai City" },
];
export const dist = [
  {
    label: "Ahmednagar",
    value: "Ahmednagar",
  },
  {
    label: "Akola",
    value: "Akola",
  },
  {
    label: "Amravati",
    value: "Amravati",
  },
  {
    label: "Aurangabad",
    value: "Aurangabad",
  },
  {
    label: "Beed",
    value: "Beed",
  },
  {
    label: "Bhandara",
    value: "Bhandara",
  },
  {
    label: "Buldhana",
    value: "Buldhana",
  },
  {
    label: "Chandrapur",
    value: "Chandrapur",
  },
  {
    label: "Dhule",
    value: "Dhule",
  },
  {
    label: "Gadchiroli",
    value: "Gadchiroli",
  },
  {
    label: "Gondia",
    value: "Gondia",
  },
  {
    label: "Hingoli",
    value: "Hingoli",
  },
  {
    label: "Jalgaon",
    value: "Jalgaon",
  },
  {
    label: "Jalna",
    value: "Jalna",
  },
  {
    label: "Kolhapur",
    value: "Kolhapur",
  },
  {
    label: "Latur",
    value: "Latur",
  },
  {
    label: "Mumbai City",
    value: "Mumbai City",
  },
  {
    label: "Mumbai Suburban",
    value: "Mumbai Suburban",
  },
  {
    label: "Nagpur",
    value: "Nagpur",
  },
  {
    label: "Nanded",
    value: "Nanded",
  },
  {
    label: "Nandurbar",
    value: "Nandurbar",
  },
  {
    label: "Nashik",
    value: "Nashik",
  },
  {
    label: "Osmanabad",
    value: "Osmanabad",
  },
  {
    label: "Palghar",
    value: "Palghar",
  },
  {
    label: "Parbhani",
    value: "Parbhani",
  },
  {
    label: "Pune",
    value: "Pune",
  },
  {
    label: "Raigad",
    value: "Raigad",
  },
  {
    label: "Ratnagiri",
    value: "Ratnagiri",
  },
  {
    label: "Sangli",
    value: "Sangli",
  },
  {
    label: "Satara",
    value: "Satara",
  },
  {
    label: "Sindhudurg",
    value: "Sindhudurg",
  },
  {
    label: "Solapur",
    value: "Solapur",
  },
  {
    label: "Thane",
    value: "Thane",
  },
  {
    label: "Wardha",
    value: "Wardha",
  },
  {
    label: "Washim",
    value: "Washim",
  },
  {
    label: "Yavatmal",
    value: "Yavatmal",
  },
];

export const category = [
  { label: "GEN/GC: General Category", value: "GEN/GC: General Category" },
  { label: "SC: Scheduled Caste", value: "SC: Scheduled Caste" },
  { label: "ST: Scheduled Tribe", value: "ST: Scheduled Tribe" },
  { label: "V.J(A): Vimukta Jati(A)", value: "V.J(A): Vimukta Jati(A)" },
  { label: "N.T(B): Nomadic Tribe(B)", value: "N.T(B): Nomadic Tribe(B)" },
  { label: "N.T(C): Nomadic Tribe(C)", value: "N.T(C): Nomadic Tribe(C)" },
  { label: "N.T(D): Nomadic Tribe(D)", value: "N.T(D): Nomadic Tribe(D)" },
  {
    label: "S.B.C: Special Backward Class",
    value: "S.B.C: Special Backward Class",
  },
  {
    label: "O.B.C: Other Backward Class",
    value: "O.B.C: Other Backward Class",
  },
  { label: "OPEN", value: "OPEN" },
  {
    label: "SEBC: Socially and Economically Backward Classes",
    value: "SEBC: Socially and Economically Backward Classes",
  },
  {
    label: "EWS: Economically Weaker Sections",
    value: "EWS: Economically Weaker Sections",
  },
];
export const grad_data = [
  { label: "SSC", value: "SSC" },

  { label: "Below SSC", value: "Below SSC" },

  { label: "HSC", value: "HSC" },

  {
    label: "ITI Diploma ",
    value: "ITI Diploma",
  },

  {
    label: "B.A",
    value: "B.A",
  },
  {
    label: "B.Arch",
    value: "B.Arch",
  },
  {
    label: "BCA",
    value: "BCA",
  },
  {
    label: "B.B.A/ B.M.S",
    value: "B.B.A/ B.M.S",
  },
  {
    label: "B.Com",
    value: "B.Com",
  },
  {
    label: "B.Ed",
    value: "B.Ed",
  },
  {
    label: "BDS",
    value: "BDS",
  },
  {
    label: "BHM",
    value: "BHM",
  },
  {
    label: "B.Pharma",
    value: "B.Pharma",
  },
  {
    label: "B.Sc",
    value: "B.Sc",
  },
  {
    label: "B.Tech/B.E.",
    value: "B.Tech/B.E.",
  },
  {
    label: "LLB",
    value: "LLB",
  },
  {
    label: "MBBS",
    value: "MBBS",
  },
  {
    label: "Diploma",
    value: "Diploma",
  },
  {
    label: "BVSC",
    value: "BVSC",
  },
  {
    label: "BAMS",
    value: "BAMS",
  },
  {
    label: "BHMS",
    value: "BHMS",
  },
  {
    label: "B.El.Ed",
    value: "B.El.Ed",
  },
  {
    label: "B.P.Ed",
    value: "B.P.Ed",
  },
  {
    label: "BHMCT",
    value: "BHMCT",
  },
  {
    label: "B.Des.",
    value: "B.Des.",
  },
  {
    label: "BFA",
    value: "BFA",
  },
  {
    label: "B.U.M.S",
    value: "B.U.M.S",
  },
  {
    label: "Other Graduate",
    value: "Other Graduate",
  },
];

export const specialization = [
  {
    label: "Advertising/Mass Communication",
    value: "Advertising/Mass Communication",
  },
  {
    label: "Agriculture",
    value: "Agriculture",
  },
  {
    label: "Anthropology",
    value: "Anthropology",
  },
  {
    label: "Architecture",
    value: "Architecture",
  },
  {
    label: "Arts &amp; Humanities",
    value: "Arts &amp; Humanities",
  },
  {
    label: "Automobile",
    value: "Automobile",
  },
  {
    label: "Aviation",
    value: "Aviation",
  },
  {
    label: "Bio-Chemistry/Bio-Technology",
    value: "Bio-Chemistry/Bio-Technology",
  },
  {
    label: "Biomedical",
    value: "Biomedical",
  },
  {
    label: "Biotechnology",
    value: "Biotechnology",
  },
  {
    label: "Ceramics",
    value: "Ceramics",
  },
  {
    label: "Chemical",
    value: "Chemical",
  },
  {
    label: "Chemistry",
    value: "Chemistry",
  },
  {
    label: "Civil",
    value: "Civil",
  },
  {
    label: "Commerce",
    value: "Commerce",
  },
  {
    label: "Communication",
    value: "Communication",
  },
  {
    label: "Computers",
    value: "Computers",
  },
  {
    label: "Dairy Technology",
    value: "Dairy Technology",
  },
  {
    label: "Dermatology",
    value: "Dermatology",
  },
  {
    label: "Economics",
    value: "Economics",
  },
  {
    label: "Electrical",
    value: "Electrical",
  },
  {
    label: "Electronics/Telecommunication",
    value: "Electronics/Telecommunication",
  },
  {
    label: "Energy",
    value: "Energy",
  },
  {
    label: "ENT",
    value: "ENT",
  },
  {
    label: "Environmental",
    value: "Environmental",
  },
  {
    label: "Fashion Designing/Other Designing",
    value: "Fashion Designing/Other Designing",
  },
  {
    label: "Film",
    value: "Film",
  },
  {
    label: "Finance",
    value: "Finance",
  },
  {
    label: "Fine Arts",
    value: "Fine Arts",
  },
  {
    label: "Food Technology",
    value: "Food Technology",
  },
  {
    label: "Hotel Management",
    value: "Hotel Management",
  },
  {
    label: "History",
    value: "History",
  },
  {
    label: "HR/Industrial Relations",
    value: "HR/Industrial Relations",
  },
  {
    label: "Immunology",
    value: "Immunology",
  },
  {
    label: "International Business",
    value: "International Business",
  },
  {
    label: "Instrumentation",
    value: "Instrumentation",
  },
  {
    label: "Journalism",
    value: "Journalism",
  },
  {
    label: "Law",
    value: "Law",
  },
  {
    label: "Literature",
    value: "Literature",
  },
  {
    label: "Marine",
    value: "Marine",
  },
  {
    label: "Marketing",
    value: "Marketing",
  },
  {
    label: "Maths",
    value: "Maths",
  },
  {
    label: "Mechanical",
    value: "Mechanical",
  },
  {
    label: "Medicine",
    value: "Medicine",
  },
  {
    label: "Metallurgy",
    value: "Metallurgy",
  },
  {
    label: "Microbiology",
    value: "Microbiology",
  },
  {
    label: "Mineral",
    value: "Mineral",
  },
  {
    label: "Mining",
    value: "Mining",
  },
  {
    label: "Neonatal",
    value: "Neonatal",
  },
  {
    label: "Nuclear",
    value: "Nuclear",
  },
  {
    label: "Obstetrics",
    value: "Obstetrics",
  },
  {
    label: "Paint/Oil",
    value: "Paint/Oil",
  },
  {
    label: "Pathology",
    value: "Pathology",
  },
  {
    label: "Petroleum",
    value: "Petroleum",
  },
  {
    label: "Pediatrics",
    value: "Pediatrics",
  },
  {
    label: "Pharmacy",
    value: "Pharmacy",
  },
  {
    label: "Physics",
    value: "Physics",
  },
  {
    label: "Plastics",
    value: "Plastics",
  },
  {
    label: "Production/Industrial",
    value: "Production/Industrial",
  },
  {
    label: "Psychiatry",
    value: "Psychiatry",
  },
  {
    label: "Psychology",
    value: "Psychology",
  },
  {
    label: "Radiology",
    value: "Radiology",
  },
  {
    label: "Rheumatology",
    value: "Rheumatology",
  },
  {
    label: "Sanskrit",
    value: "Sanskrit",
  },
  {
    label: "Sociology",
    value: "Sociology",
  },
  {
    label: "Statistics",
    value: "Statistics",
  },
  {
    label: "Systems",
    value: "Systems",
  },
  {
    label: "Textile",
    value: "Textile",
  },
  {
    label: "Vocational Courses",
    value: "Vocational Courses",
  },
  {
    label: "Nursing",
    value: "Nursing",
  },
  {
    label: "Linguistics",
    value: "Linguistics",
  },
  {
    label: "Political Science",
    value: "Political Science",
  },
  {
    label: "Home Science",
    value: "Home Science",
  },
  {
    label: "Special Education",
    value: "Special Education",
  },
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "Music",
    value: "Music",
  },
  {
    label: "Botany",
    value: "Botany",
  },
  {
    label: "Philosophy",
    value: "Philosophy",
  },
  {
    label: "Zoology",
    value: "Zoology",
  },
  {
    label: "Physical Education",
    value: "Physical Education",
  },
  {
    label: "Genetics",
    value: "Genetics",
  },
  {
    label: "Astrophysics",
    value: "Astrophysics",
  },
  {
    label: "Biophysics",
    value: "Biophysics",
  },
  {
    label: "Other Specialization",
    value: "Other Specialization",
  },
];

export const salary_range = [
  { label: "Upto 10000", value: "Upto 10000" },
  { label: "10000 - 15000", value: "10000 - 15000" },
  { label: "15000 - 20000", value: "15000 - 20000" },
  { label: "20000 - 25000", value: "20000 - 25000" },
  { label: "25000 - 30000", value: "25000 - 30000" },
  { label: "30000 - 40000", value: "30000 - 40000" },
  { label: "40000 - 50000", value: "40000 - 50000" },
  { label: "50000 +", value: "50000 +" },
];

export const fav = [
  {
    label: "Hospitality Sector ",
    value: "Hospitality Sector ",
  },
  {
    label: "Accountant ",
    value: "Accountant ",
  },
  {
    label: "Sales and Marketing",
    value: "Sales and Marketing",
  },
  {
    label: "Business Development ",
    value: "Business Development ",
  },
  {
    label: "Customer Relationship Officer",
    value: "Customer Relationship Officer",
  },
  {
    label: "Fitter / Electrician / Mechanical Operator",
    value: "Fitter / Electrician / Mechanical Operator",
  },
  {
    label: "Tele Caller",
    value: "Tele Caller",
  },
  {
    label: "Field Officer ",
    value: "Field Officer ",
  },
  {
    label: "Bike Rider",
    value: "Bike Rider",
  },
  {
    label: "Car Driver",
    value: "Car Driver",
  },
  {
    label: "Chef",
    value: "Chef",
  },
  {
    label: "Data Entry Operator",
    value: "Data Entry Operator",
  },
  {
    label: "Back Office Administration",
    value: "Back Office Administration",
  },
  {
    label: "Housekeeping ",
    value: "Housekeeping ",
  },
  {
    label: "Retail",
    value: "Retail",
  },
  {
    label: "Nursing ",
    value: "Nursing ",
  },
  {
    label: "Ward Boy",
    value: "Ward Boy",
  },
  {
    label: "Security Guard",
    value: "Security Guard",
  },
  {
    label: " Commi / Cook/ chef ",
    value: " Commi / Cook/ chef ",
  },
  {
    label: " Steward, Restaurant Manager / Bartender ",
    value: " Steward, Restaurant Manager / Bartender ",
  },
  {
    label: "Housekeeping Staff/ Executive Storekeeper ",
    value: "Housekeeping Staff/ Executive Storekeeper ",
  },
  {
    label: " Front office staff/ Executive",
    value: " Front office staff/ Executive",
  },
];

export const state=[
  
]