/**
 * Form Elemets
 */
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Select from "react-select";
import { job_location, talukas } from "../../constants.js";
import watsapp from "../../assets/img/profiles/watsapp.png";
import cover from "../../assets/img/profiles/website-banner.jpg";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner.jsx";
import cover1 from "../../assets/img/profiles/SBA-003.jpg";
import jobPDF from "../../assets/address.pdf";
import deleteicon from "../../assets/img/profiles/delete-bin-line.svg";
import { useNavigate } from "react-router-dom";
import { GenderL, stateL } from "../formConst.js";
import styled from "@emotion/styled";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Stepper,
  StepLabel,
  Step,
  Button,
  useMediaQuery,
} from "@mui/material";
import {
  SBox1,
  SBox2,
  SBox3,
  SBox4,
  SBox5,
  SBox7,
  SButton1,
  SButton2,
  SButton3,
  STypography1,
  STypography10,
  STypography11,
  STypography2,
  STypography3,
  STypography4,
  STypography5,
  STypography6,
  STypography7,
  STypography8,
  STypography9,
} from "../../assets/style/Styles.js";
import { useFormik } from "formik";
import * as Yup from "yup";
import img1 from "../../assets/images/imgOne.png";
import img2 from "../../assets/images/imgTwo.png";
import img4 from "../../assets/images/imgFour.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
//import validator from "validator";
import {
  skills,
  sector,
  gender,
  taluka,
  specialization,
  grad_data,
  category,
  dist,
  maritalStatus,
  partTime,
  priorities,
  rank,
  serviceType,
  experience,
  salary_range,
  fav,
  opportunities,
} from "../../constants.js";
import axios from "axios";
import { checkPropTypes } from "prop-types";
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { Modal } from "react-bootstrap";
// import { Box } from "@mui/material";

const HorizontalForm = () => {
  // const base_url =
  //   "https://i60vrmt4cl.execute-api.ap-south-1.amazonaws.com/dev";
  // const base_url = "https://samarth.uat.api.globalsapio.com";
  // const base_url = "https://samarth.prod.api.sapioglobal.com";
  // const base_url = process.env.REACT_APP_BASE_URL;

  const vk_url =
    "https://vkmssit.vakrangee.in/partner-payments/save-cust-details";
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [preffere, setPreffere] = useState([]);
  const [submitform, setSubmitform] = useState(false);
  const [submitformOne, setSubmitformOne] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [show, setShow] = useState(false);
  const [obj, setObj] = useState({
    //org_address: "",
    org_state: "",
    org_district: "",
    taluka: "",
    org_pincode: "",
    mobnum: "",
    email: "",
    firstnm: "",
    lastnm: "",
    marital_status: "",
    gender: "",
    birth_date: "",
  });
  const [selectedState, setSelectedState] = useState("");
  const [resumefile, setResumeFile] = useState("");
  const [languagesall, setLanguagesall] = useState([]);
  const [formDetails, setFormDetails] = useState({
    self_opportunity: "",
    first_name: "",
    last_name: "",
    //father_name: "",
    //mother_name: "",
    religion: "",
    mother_tongue: "",
    email: "",
    dob: "",
    gender: "",
    height: "",
    address: "",
    state: "",
    priority: "",
    district: "",
    taluka: "",
    qualification: "",
    stream: "",
    mobile_number: "",
    //cast: "",
    job_Location: "",
    sub_cast: "",
    marital_status: "",
    aadhar: "",
    //part_time: "",
    experience: "",
    // job_fair_location: "",
    curr_industry: "",
    skills: "",
    // prefferd work 1
    // preffered_work:"",
    // prefferd work multiple
    des_work: "",
    job_role: "",
    wa_optin: "false",
    resume_required: "1",
    // stay_mah: {
    //   year: null,
    //   month: null,
    // },
    // if_disabled: {
    //   nature: "",
    //   percentage: null,
    // },
    // ex_serviceman: {
    //   type: "",
    //   rank: "",
    //   total_service: null,
    // },
    language: {
      marathi: {
        speak: false,
        read: false,
        write: false,
        fluency: false,
      },
      hindi: {
        speak: false,
        read: false,
        write: false,
        fluency: false,
      },
      english: {
        speak: false,
        read: false,
        write: false,
        fluency: false,
      },
    },
    // wiling_for: {
    //   state_police_service: false,
    //   state_reserve_police_force: false,
    //   armed_force: false,
    //   private_security_services: false,
    //   sales_service: false,
    //   type_of_agents: false,
    //   sweeper_cleaner: false,
    //   shop_customer_services: false,
    // },
    // qualification_and_training: [
    //   {
    //     exam_passed: "",
    //     year: null,
    //     medium: "",
    //     percentage: null,
    //   },
    // ],
    //preffered_industry: "",
    des_industry: "",
    // salary: ""
    current_salary: "",
    desired_salary: "",
  });
  const [joblocation, setjobLocation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [check, setCheck] = useState(true);
  const [desi, setDesi] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  // const [state, setState] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [workExp, setWorkExp] = useState([
    {
      employer_name: "",
      designation: "",
      job_type: "",
      joining_date: "",
      relieving_date: "",
      totel_exp: null,
    },
  ]);

  const [educationQ, setEducationQ] = useState([
    {
      exam_passed: "",
      year: null,
      medium: "",
      percentage: null,
    },
  ]);
  const ref = useRef();

  const stepperStyle = {
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: "#FF7A00",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: "#FF7A00",
      },
    },
  };

  const steps = ["Personal Information", "Career Profile Information"];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const matchmd = useMediaQuery("max-width:700px");
  const matchsm = useMediaQuery("(min-width:600px)");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const syncWait = (ms) => {
    const end = Date.now() + ms;
    while (Date.now() < end) continue;
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const [genderOption, setGenderOption] = useState([]);
  const [educationOption, setEducationOption] = useState([]);
  const [degreeOption, setDegreeOption] = useState([]);
  const [streamOption, setStreamOption] = useState([]);
  const [salaryList, setSalaryList] = useState([]);
  const [industryOption, setIndustryOption] = useState([]);
  const [casteOption, setCasteOption] = useState([]);
  const [experienceOption, setExperienceOption] = useState([]);
  const [maritalstatusList, setMaritalStatusList] = useState([]);
  const [State, setState] = useState([]);
  const [District, setDistrict] = useState([]);
  const [Taluka, setTaluka] = useState([]);
  const [jobRoleList, setJobRoleList] = useState([]);
  const [jobLocationList, setJobLocationList] = useState([]);
  const [verifyStatus, setVerifyStatus] = useState(false);
  const [skillsList, setSkillsList] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [intervalState, setIntervalState] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [resumeCheck, setResumeCheck] = useState(false);
  const [resume, setResume] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const resumeOption = [
    {
      label: "With Resume",
      value: "1",
    },
    {
      label: "Without Resume",
      value: "0",
    },
  ];

  const SBox6 = styled(Box)(
    matchsm
      ? {
          p: 3,
          ml: "auto",
          mr: "auto",
          background: "rgba(212, 229, 255, 0.45)",
          borderRadius: "9px",
          border: "3px dashed rgba(45, 91, 255, 1)",
          width: "350px",
          minHeight: "185px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          cursor: "pointer",
        }
      : {
          p: 3,
          ml: "auto",
          mr: "auto",
          background: "rgba(212, 229, 255, 0.45)",
          borderRadius: "9px",
          border: "3px dashed rgba(45, 91, 255, 1)",
          width: "320px",
          minHeight: "185px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          cursor: "pointer",
        }
  );
  //// education,degree,stream,jobrole,joblocation,state,district,taluka
  {
    /*Options data */
  }
  // {
  //   paymentLoading &&
  //     setTimeout(() => {
  //       console.log("Payement closed");
  //       setPaymentLoading(false);
  //       Swal.fire({
  //         icon: "error",
  //         title: "Transaction Failed",
  //         // text: "Payment Failed",
  //         html:
  //           "<pre>" +
  //           `<p align="left"><b>Service Name  : </b> service</p>` +
  //           `<p align="left"><b>vakTransRefNo : </b> 3213321000632</p>` +
  //           `<p align="left"><b>b2bTransRefNo : </b> 915487548745</p>` +
  //           "</pre>",
  //         customClass: {
  //           popup: "format-pre",
  //         },
  //       });
  //     }, 60000);
  // }

  const fetchlang = async () => {
    try {
      const res = await axios.get(
        "https://samarth.prod.api.sapioglobal.com/jobseeker/account/language"
      );
      const data = await res.data;
      setLanguageList(
        data?.map((v) => {
          return {
            value: v,
            label: v,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSkills = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/skills`)
      .then((res) => {
        setSkillsList(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchlang();
    fetchSkills();
  }, []);

  console.log(formDetails);
  const addFields = (e) => {
    e.preventDefault();
    let newfield = {
      employer_name: "",
      designation: "",
      job_type: "",
      joining_date: "",
      relieving_date: "",
      totel_exp: null,
    };

    setWorkExp([...workExp, newfield]);
  };
  const handleFormChange = (index, event) => {
    let data = [...workExp];
    data[index][event.target.name] = event.target.value;
    setWorkExp(data);
  };
  const removeFields = (index) => {
    let data = [...workExp];
    data.splice(index, 1);
    setWorkExp(data);
  };

  const addFields1 = (e) => {
    e.preventDefault();
    let newfield = {
      exam_passed: "",
      year: null,
      medium: "",
      percentage: null,
    };

    setEducationQ([...educationQ, newfield]);
  };
  const handleFormChange1 = (index, event) => {
    let data = [...educationQ];
    data[index][event.target.name] = event.target.value;
    setEducationQ(data);
  };
  const removeFields1 = (index) => {
    let data = [...educationQ];
    data.splice(index, 1);
    setEducationQ(data);
  };

  // useEffect(() => {
  //   const fetchStateList = () => {
  //     axios
  //       .post(
  //         `https://dashboardapi.mysba.globalsapio.com/State`,
  //         {
  //           level: 1,
  //           thislevel: "India",
  //           locale: "en",
  //         }
  //         // {
  //         //   headers: {
  //         //     Authorization: `Bearer ${token}`,
  //         //   },
  //         // }
  //       )
  //       .then((res) => {
  //         // console.log("State", res.data);
  //         // var StateList = res.data[0].sort().map(function (item) {
  //         //   return { label: item, value: item };
  //         // });
  //         setState(res.data);
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   };
  //   fetchStateList();
  // }, []);

  // useEffect(() => {
  //   const fetchCity = () => {
  //     axios
  //       .post(
  //         `https://dashboardapi.mysba.globalsapio.com/District`,
  //         {
  //           level: 2,
  //           thislevel: formDetails.city,
  //           locale: "en",
  //         }
  //         // {
  //         //   headers: {
  //         //     Authorization: `Bearer ${token}`,
  //         //   },
  //         // }
  //       )
  //       .then((res) => {
  //         setDistrictList(res.data);

  //         //console.log("District", res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   //fetchStateList();
  //   fetchCity();
  // }, [formDetails]);

  // useEffect(() => {
  //   axios
  //     .get(" https://7e55uus5l5.execute-api.ap-south-1.amazonaws.com/dev/designation")
  //     .then((res) => {
  //       setDesi(
  //         Object.values(res.data.name).map((ele) => ({
  //           label: ele,
  //           value: ele,
  //         }))
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  // console.log(desi);

  const fetchState = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/state`)
      .then((res) => {
        console.log("state", res.data);
        setState(res.data);
      })
      .catch((e) => {
        console.log("state", e);
      });
  };

  const fetchDistrict = async () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/district?${paramString}`)
      .then((res) => {
        console.log("district", res.data);
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log("district", err);
      });
  };

  const fetchTaluka = async () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/generic/taluka`, {
        state: formDetails.state,
        district: formDetails.district,
      })
      .then((res) => {
        console.log("taluka", res.data);
        setTaluka(res.data);
      })
      .catch((err) => {
        console.log("taluka", err);
      });
  };

  const fetchDegree = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/degree`)
      .then((res) => {
        console.log("Degree", res.data);
        setDegreeOption(res.data);
      })
      .catch((e) => {
        console.log("degree", e);
      });
  };
  const fetchStream = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/stream`)
      .then((res) => {
        console.log("Stream", res.data);
        setStreamOption(res.data);
      })
      .catch((e) => {
        console.log("stream", e);
      });
  };
  const fetchSalary = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };
  const fetchGender = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/gender`)
      .then((res) => {
        console.log("gender", res.data);
        setGenderOption(res.data);
      })
      .catch((err) => {
        console.log("gender", err);
      });
  };
  const fetchEducation = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/qualification`)
      .then((res) => {
        console.log("education", res.data);
        setEducationOption(res.data);
      })
      .catch((err) => {
        console.log("education", err);
      });
  };
  const fetchIndustry = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/industry`)
      .then((res) => {
        console.log("industry", res.data);
        setIndustryOption(res.data);
      })
      .catch((err) => {
        console.log("industry", err);
      });
  };
  const fetchCaste = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/caste`)
      .then((res) => {
        console.log("caste", res.data);
        setCasteOption(res.data);
      })
      .catch((err) => {
        console.log("caste", err);
      });
  };
  const fetchExperience = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/experience`)
      .then((res) => {
        console.log("experience", res.data);
        const data = res.data;
        setExperienceOption(data);
        // setExperienceOption((data)=>{
        //   data.map((ele)=>({
        //     label: `${ele} Years`,
        //     value: ele
        //   }))
        // });
      })
      .catch((err) => {
        console.log("experience", err);
      });
  };
  const fetchJobRole = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/designation`)
      .then((res) => {
        console.log("job_role", res.data);
        const data = res.data;
        setJobRoleList(data);
        // setExperienceOption((data)=>{
        //   data.map((ele)=>({
        //     label: `${ele} Years`,
        //     value: ele
        //   }))
        // });
      })
      .catch((err) => {
        console.log("job_role", err);
      });
  };
  const fetchJobLocationList = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/jobseeker/fetch_jf_location`)
      .then((res) => {
        console.log("job_location", res.data.job_fair_location);
        const data = res.data;
        setJobLocationList(data.job_fair_location);
        // setExperienceOption((data)=>{
        //   data.map((ele)=>({
        //     label: `${ele} Years`,
        //     value: ele
        //   }))
        // });
      })
      .catch((err) => {
        console.log("jf_location", err);
      });
  };
  const fetchMaritalStatus = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/generic/maritalstatus`)
      .then((res) => {
        console.log(res.data);
        setMaritalStatusList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  {
    /*
  const fetchData2 = async () => {
    try {
      const response = await axios.get("https://7e55uus5l5.execute-api.ap-south-1.amazonaws.com/dev/industrylist")
      const data = response.data
      console.log(data)
    }
    catch (err) {
      console.log(err)
    }
  }
  */
  }

  // const PaymentResponseFunc = async ()=>{
  //   await axios.get(
  //     "https://i60vrmt4cl.execute-api.ap-south-1.amazonaws.com/dev/jobseeker/vk_payment"
  //   );
  // }

  useEffect(() => {
    // fetchData1()
    //fetchData2()
    fetchGender();
    fetchEducation();
    fetchExperience();
    fetchCaste();
    fetchIndustry();
    fetchJobRole();
    fetchJobLocationList();
    fetchDegree();
    fetchMaritalStatus();
    fetchStream();
    fetchSalary();
    // fetchMaritalStatus();
  }, []);

  useEffect(() => {
    fetchState();
    // fetchQuesList();
  }, []);

  useEffect(() => {
    fetchDistrict();
  }, [selectedState]);

  useEffect(() => {
    fetchTaluka();
  }, [formDetails.district]);

  const options = [
    // {
    //   label: "Favourites",
    //   options: fav,
    // },
    {
      // label: "Others",
      options: desi,
    },
    // { label: "A root option", value: "value_3" },
    // { label: "Another root option", value: "value_4" },
  ];

  const handleResume = (File) => {
    const validExt = ["pdf", "doc"];
    const fileExt = File.type.split("/")[1];
    return validExt.includes(fileExt);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (handleResume(file) && file.size / 1024 < 5120) {
      setResumeFile(file);
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either pdf or doc.",
        showConfirmButton: false,
        timer: 2000,
      });
      ref.current.value = "";
      setResumeFile("");
    }
  };

  // params
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  console.log(params);
  const query1 = searchParams.get("vkid");
  const query2 = searchParams.get("state");
  const query3 = searchParams.get("district");
  console.log("query ", query1, query2, query3);
  console.log(formDetails.first_name);

  const checkMobileNum = (number) => {
    const params = {
      mobile: number,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/jobseeker/fetch_vk_data?${paramString}`
      )
      .then((res) => {
        console.log("user exists");
        // setVerifyStatus(true);
        // return true;
        Swal.fire({
          icon: "info",
          title: "",
          text: "Try new mobile num",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((err) => {
        console.log("New user", err);
        handleSubmit();
        // setVerifyStatus(false);
        // return false;
        // Swal.fire({
        //   icon: "error",
        //   title: "",
        //   text: err.data,
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      });
  };
  // const interval =
  const callApi = () => {
    axios
      // .get(`${process.env.REACT_APP_BASE_URL}/jobseeker/vk_paymentstatus`, {
      .get(`${process.env.REACT_APP_BASE_URL}/form/vkpaymentstatus`, {
        params: {
          mobile: `91${mobileNumber}`,
        },
      })
      .then((response) => {
        // Handle the API response here
        setPaymentStatus(response.data["payment status"]);
        setTransactionId(response.data["transaction"]);
        console.log(response.data);
        (response.data["payment status"] === "success" ||
          response.data["payment status"] === "fail") &&
          setIntervalState(true);
        if (response.data["payment status"] === "success") {
          setPaymentLoading(false);
          Swal.fire({
            icon: "success",
            title: "Transaction Successful",
            // text: "Payment Successfull",
            html:
              "<pre>" +
              `<p align="left"><b>Service Name  : </b> ${response.data["service"]}</p>` +
              `<p align="left"><b>vakTransRefNo : </b> ${response.data["transaction"]}</p>` +
              `<p align="left"><b>b2bTransRefNo : </b> 91${formDetails.mobile_number}</p>` +
              "</pre>",
            customClass: {
              popup: "format-pre",
            },
            // html:
            //   `<b>Service Name : </b> ` +
            //   `OTU` +
            //   "</br>" +
            //   "<b>vakTransRefNo : </b>" +
            //   `${response.data["transaction"]}` +
            //   "</br>" +
            //   "<b>b2bTransRefNo : </b>" +
            //   `91${formDetails.mobile_number}`,
            // +
            // "</br>" +
            // "<b>Transaction Status : </b>" +
            // "Transaction Successful",
            // showConfirmButton: false,
            // timer: 4000,
          });
          // .then(
          //   () => (
          //     // CallbackFunction()
          //     console.log("payement status sucess and clear"),
          //     window.location.reload()
          //   )
          // );
          // window.location.reload();
          CallbackFunction(
            response.data["payment status"],
            response.data["transaction"],
            response.data["amount"]
            // response.data["service"]
          );
        } else if (response.data["payment status"] === "fail") {
          setPaymentLoading(false);
          Swal.fire({
            icon: "error",
            title: "Transaction Failed",
            // text: "Payment Failed",
            html:
              "<pre>" +
              `<p align="left"><b>Service Name  : </b> ${response.data["service"]}</p>` +
              `<p align="left"><b>vakTransRefNo : </b> ${response.data["transaction"]}</p>` +
              `<p align="left"><b>b2bTransRefNo : </b> 91${formDetails.mobile_number}</p>` +
              "</pre>",
            customClass: {
              popup: "format-pre",
            },
            // html:
            //   `<b>Service Name : </b> ` +
            //   `OTU` +
            //   "</br>" +
            //   "<b>vakTransRefNo : </b>" +
            //   `${response.data["transaction"]}` +
            //   "</br>" +
            //   "<b>b2bTransRefNo : </b>" +
            //   `91${formDetails.mobile_number}`,
            // +
            // "</br>" +
            // "<b>Transaction Status : </b>" +
            // "Transaction Failed",
            // showConfirmButton: false,
            // timer: 4000,
          });
          // .then(
          //   () => () => (
          //     // CallbackFunction()
          //     console.log("payement status sucess and clear"),
          //     window.location.reload()
          //   )
          // );
          // window.location.reload();
          CallbackFunction(
            response.data["payment status"],
            response.data["transaction"],
            response.data["amount"]
            // response.data["service"]
          );
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error(error);
      });
  };
  let Interval;
  const IntervalFunc = () => {
    Interval = setInterval(callApi, 1000);
  };

  const UrlFunc = (status, transId, amt) => {
    axios
      .post(
        // "https://i60vrmt4cl.execute-api.ap-south-1.amazonaws.com/dev/jobseeker/vk_payment",
        // `https://vakrangee.mysba.globalsapio.com/?vkid=${query1}&state=${query2}&district=${query3}`,
        `${process.env.REACT_APP_BASE_URL}/form/vkpayment`,
        {
          vakTransRefNo: transId,
          b2bTransRefNo: `91${formDetails.mobile_number}`,
          amount: amt,
          responseMessage:
            status === "fail"
              ? "Transaction Failed"
              : status === "success"
              ? "Transaction Successful"
              : "",
        }
      )
      .then((res) => {
        console.log("Urlfunc :", res.data);
      })
      .catch((err) => {
        console.log("Url func:", err);
      });
  };

  const CallbackFunction = (status, transId, amt) => {
    // useEffect(() => {
    //   // Start the interval when the component mounts
    //   const interval = setInterval(callApi, 1000); // Call the API every second (1000 milliseconds)
    //   return () => {
    //     // Clear the interval when the component unmounts
    //     clearInterval(interval);
    //   };
    //   // setInterval(callApi, 1000);
    // }, []);
    // const intervalId = setInterval(() => {
    //   const response = callApi();
    //   if (response.data["payment status"] === "success") {
    //     // Clear the interval if the response status is 'success'
    //     clearInterval(intervalId);
    //   } else if (response.data["payment status"] === "fail") {
    //     clearInterval(intervalId);
    //   }
    // }, 1000);
    // let interval;
    // interval = setInterval(callApi, 1000);
    console.log("callback func");
    UrlFunc(status, transId, amt);
    clearInterval(Interval);
  };
  console.log(intervalState, paymentStatus);
  console.log(formDetails.dob, "aaa");

  const handleSubmit = async () => {
    setIsLoading(true);
    let formData = new FormData();
    // formData.append("params",JSON.stringify(params));
    formData.append("VKID", query1); //
    formData.append("VKID_state", query2); //
    formData.append("VKID_district", query3); //

    formData.append("firstname", formDetails.first_name); //
    formData.append("lastname", formDetails.last_name); //
    formData.append("email", formDetails.email); //
    formData.append("mobnum", formDetails.mobile_number); //
    formData.append("date_of_birth", formDetails.dob); //
    formData.append("gender", formDetails.gender); //
    formData.append("marital_status", formDetails.marital_status); //
    formData.append("state", formDetails.state); //
    formData.append("district", formDetails.district); //
    formData.append("taluka", formDetails.taluka); //
    formData.append("pincode", formDetails.pincode ? formDetails.pincode : ""); //
    formData.append("highest_education", formDetails.qualification); //
    formData.append("skills", preffere.map((ele) => ele.value).toString()); //
    formData.append("stream", formDetails.stream); //
    formData.append("job_role", formDetails.job_role); //
    formData.append("self_opportunity", formDetails.self_opportunity); //
    formData.append("current_industry", formDetails.curr_industry); //
    formData.append(
      "current_salary",
      formDetails.current_salary ? formDetails.current_salary : ""
    ); //
    formData.append(
      "desired_salary",
      formDetails.desired_salary ? formDetails.desired_salary : ""
    ); //
    formData.append(
      "languages",
      languagesall.map((ele) => ele.value).toString()
    ); //
    formData.append("experience", formDetails.experience); //
    formData.append("wa_optin", "true"); //
    formData.append("resume", resumefile); //
    formData.append("resume_required", resumeCheck ? "1" : "0"); //
    // formData.append("datasource", "VK_FORM");
    // formData.append("datasource", "SMR-CP-VK-805751");
    formData.append(
      "datasource",
      query1 !== null && query1 !== "" ? `VKID_${query1}` : "VK_FORM"
    );

    // formData.append("vkid",11);
    // const config = {
    //   headers: {
    //     'content-type': 'multipart/form-data',
    //   },
    // }

    // if(formDetails.first_name!=="" && formDetails.last_name!=="" && formDetails.gender!=="" && formDetails.dob!==""
    //     && formDetails.address!=="" && formDetails.city!=="" && formDetails.district!=="" && formDetails.mobile_number!==""
    //      && formDetails.curr_industry!=="" && formDetails.des_industry!=="" && formDetails.des_work!=="" && formDetails.notify!==""){

    // axios
    //   .post(`${vk_url}`, {
    //     serviceName: "OTU",
    //     vkid: formDetails.query1,
    //     partnerRequestId: `91${formDetails.mobile_number}`,
    //     custName: `${formDetails.first_name} ${formDetails.last_name}`,
    //     custEmail: formDetails.email,
    //     custMobileNumber: formDetails.mobile_number,
    //     custDob: moment(formDetails.dob).format("YYYY-MM-DD"),
    //     custGender: formDetails.gender === "Male" ? "M" : "F",
    //     custAddress: `${formDetails.taluka} ${formDetails.district} ${formDetails.state}`,
    //     custPincode: formDetails.pincode,
    //     custOrderAmount: "25",
    //     partnerCallbackUrl: process.env.REACT_APP_BASE_URL,
    //     altCustEmail: "",
    //     altCustMobileNumber: "",
    //     transactionFlag: 1,

    //     // serviceName: "OTU",
    //     // vkid: "MH123456",
    //     // partnerRequestId: "202203011514",
    //     // custName: "Navin Prasad",
    //     // custEmail: "test@gmail.com",
    //     // custMobileNumber: 999999999,
    //     // custDob: "1979-01-21",
    //     // custGender: "M",
    //     // custAddress: "Sector 6 Chandigarh",
    //     // custPincode: 600493,
    //     // custOrderAmount: "9000",
    //     // partnerCallbackUrl: "https://youtube.com",
    //     // altCustEmail: "",
    //     // altCustMobileNumber: "",
    //     // transactionFlag: 1,
    //   })
    //   .then((res) => {
    //     console.log("payment api response:", res);
    //     Swal.fire({
    //       icon: "success",
    //       title: "",
    //       text: "Payment Successfully requested",
    //       showConfirmButton: false,
    //       timer: 2000,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log("payment err response:", err);
    //     Swal.fire({
    //       icon: "error",
    //       title: "",
    //       text: "Payment request fail",
    //       showConfirmButton: false,
    //       timer: 2000,
    //     });
    //   });

    await axios
      .post(
        // "https://7e55uus5l5.execute-api.ap-south-1.amazonaws.com/dev/jobvakrangee",
        // "https://60a4-2409-4072-8b-17b6-7947-10f5-712d-91e9.in.ngrok.io/jobvakrangee",
        // `${process.env.REACT_APP_BASE_URL}/jobseeker/register/vk`,
        `${process.env.REACT_APP_BASE_URL}/form/vakrangee`,
        // {
        //   params: params,
        //   first_name: formDetails.first_name,
        //   last_name: formDetails.last_name,
        //   father_name: formDetails.father_name,
        //   mother_name: formDetails.mother_name,
        //   // height:formDetails.height,
        //   email: formDetails.email,
        //   dob: formDetails.dob,
        //   gender: formDetails.gender,
        //   marital_status: formDetails.marital_status,
        //   state: formDetails.city,
        //   // mother_tongue:formDetails.mother_tongue,
        //   // stay_mah:formDetails.stay_mah,
        //   part_time: formDetails.part_time,
        //   district: formDetails.district,
        //   // taluka: formDetails.taluka,
        //   // religion:formDetails.religion,
        //   qualification: formDetails.qualification,
        //   stream: formDetails.stream,
        //   mobile_number: formDetails.mobile_number,
        //   cast: formDetails.cast,
        //   // sub_cast: formDetails.sub_cast,
        //   aadhar: formDetails.aadhar,
        //   // priority:formDetails.priority,
        //   // job_fair_location: formDetails.job_Location,
        //   curr_industry: formDetails.curr_industry,
        //   address: formDetails.address,
        //   // des_work: formDetails.des_work,
        //   experience: formDetails.experience,
        //   // if_disabled:formDetails.if_disabled,
        //   // ex_serviceman:formDetails.ex_serviceman,
        //   notify: check ? 1 : 0,
        //   // work_experience: workExp,
        //   // qualification_and_training: educationQ,
        //   // wiling_for:formDetails.wiling_for,
        //   language: formDetails.language,
        //   //preffered_industry: formDetails.preffered_industry,
        //   des_industry: formDetails.des_industry,
        //   salary: formDetails.salary,
        //   des_work: preffere.map((ele) => ele.value).toString(),
        //   resume: resumefile
        // }
        formData
        // {
        //   params:{
        //     params: JSON.stringify(params),
        //     language: JSON.stringify(formDetails.language),
        //   }
        // }
        // ,config
      )
      .then((res) => {
        // console.log(res.response.data, "Line 78")
        // alert("form Submitted");
        setIsLoading(false);
        res.data["Payment Status"]
          ? Swal.fire({
              icon: "error",
              title: "Error",
              text: "Duplicate Data",
              showConfirmButton: false,
              timer: 3000,
            })
          : Swal.fire({
              icon: "success",
              //title: "",
              title: "Details Submitted Successfully",
              showConfirmButton: false,
              timer: 2000,
            }).then(
              () => (
                // navigate(res.data["Payment URL"])
                // (window.location.href = res.data["Payment URL"])
                res.data["Payment URL"] &&
                  window.open(res.data["Payment URL"], "_blank"),
                // res.data["Payment Status"] &&
                //   Swal.fire({
                //     icon: "error",
                //     title: "Error",
                //     text: "Duplicate Data",
                //     showConfirmButton: false,
                //     timer: 3000,
                //   }),
                res.data["Payment URL"] && setPaymentLoading(true),
                res.data["Payment URL"] && IntervalFunc(),
                res.data["Payment URL"] &&
                  setTimeout(
                    () => (
                      console.log("count:", paymentLoading),
                      !paymentLoading && setPaymentLoading(false),
                      !paymentLoading && clearInterval(Interval),
                      !paymentLoading &&
                        Swal.fire({
                          icon: "error",
                          title: "Transaction Timeout",
                          // text: "Payment Failed",
                          // html:
                          //   "<pre>" +
                          //   `<p align="left"><b>Service Name  : </b> service</p>` +
                          //   `<p align="left"><b>vakTransRefNo : </b> 3213321000632</p>` +
                          //   `<p align="left"><b>b2bTransRefNo : </b> 915487548745</p>` +
                          //   "</pre>",
                          // customClass: {
                          //   popup: "format-pre",
                          // },
                        })
                      // CallbackFunction(
                      //   "fail",
                      //   `91${formDetails.mobile_number},"25.00"`
                      // )
                    ),
                    180000
                  )
                // CallbackFunction()
                // setInterval(callApi, 1000)
              )
            );
        setFormDetails({
          self_opportunity: "",
          first_name: "",
          last_name: "",
          //father_name: "",
          //mother_name: "",
          // religion: "",
          mother_tongue: "",
          email: "",
          dob: "",
          gender: "",
          height: "",
          address: "",
          state: "",
          priority: "",
          district: "",
          taluka: "",
          qualification: "",
          stream: "",
          //degree: "",
          skills: "",
          mobile_number: "",
          cast: "",
          // job_Location: "",
          // sub_cast: "",
          marital_status: "",
          aadhar: "",
          part_time: "",
          // job_fair_location: "",
          curr_industry: "",
          //preffered_industry: "",
          job_role: "",
          des_industry: "",
          // des_work: "",
          // salary: "",
          desired_salary: "",
          current_salary: "",
          experience: "",
          // preffered_work:"",
          preffere: [],
          resume: "",
          wa_optin: "false",
          resume_required: "1",
          // stay_mah: {
          //   year: null,
          //   month: null,
          // },
          // if_disabled: {
          //   nature: "",
          //   percentage: null,
          // },
          // ex_serviceman: {
          //   type: "",
          //   rank: "",
          //   total_service: null,
          // },
          language: {
            marathi: {
              speak: false,
              read: false,
              write: false,
              fluency: false,
            },
            hindi: {
              speak: false,
              read: false,
              write: false,
              fluency: false,
            },
            english: {
              speak: false,
              read: false,
              write: false,
              fluency: false,
            },
          },
          // wiling_for: {
          //   state_police_service: false,
          //   state_reserve_police_force: false,
          //   armed_force: false,
          //   private_security_services: false,
          //   sales_service: false,
          //   type_of_agents: false,
          //   sweeper_cleaner: false,
          //   shop_customer_services: false,
          // },
        });
        setLanguagesall([]);
        setCheck(true);
        setjobLocation([]);
        setWorkExp([
          {
            employer_name: "",
            designation: "",
            job_type: "",
            joining_date: "",
            relieving_date: "",
            totel_exp: null,
          },
        ]);
        setEducationQ([
          {
            exam_passed: "",
            year: null,
            medium: "",
            percentage: null,
          },
        ]);
        setResumeCheck(false);
      })
      .catch((err) => {
        // console.log(err.response.data["Developer Message"]);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "",
          text: err.response.data["Developer Message"],
          // text: "Provide all required feilds",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  const formOne = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstnm: "",
      lastnm: "",
      email: "",
      mobnum: "",
      birth_date: "",
      gender: "",
      marital_status: "",
      //org_address: "",
      org_state: "",
      org_district: "",
      taluka: "",
      org_pincode: "",
    },
    validationSchema: Yup.object().shape({
      //org_address: Yup.string().required("Please enter address"),
      org_state: Yup.string().required("Please select state"),
      org_district: Yup.string().required("Please select district"),
      org_pincode: Yup.string()
        .notRequired()
        .length(6, "Pincode should be 6 digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
      //industry: Yup.string().required("Please select industry"),
      email: Yup.string().notRequired().email("Please enter valid email"),
      mobnum: Yup.string()
        .required("Please enter mobile number")
        .length(10, "Mobile number should be 10 digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required("Please enter last name"),
      gender: Yup.string().required("Please select gender"),
      birth_date: Yup.string().required("Please enter date of birth"),
    }),
    onSubmit: async (values) => {
      setOpen1(true);
      setMobileNumber(values.mobnum);
      try {
        const params = {
          mobile: values.mobnum,
        };
        const paramString = new URLSearchParams(params).toString();
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/jobseeker/fetch_jc_data?${paramString}`
        );
        if (res.data) {
          setOpen1(false);
        }

        Swal.fire({
          icon: "info",
          title: "",
          text: "Try new mobile number",
          showConfirmButton: false,
          timer: 2000,
        });
      } catch (err) {
        try {
          syncWait(2000);
          setObj({
            //org_address: values.org_address,
            org_state: values.org_state,
            org_district: values.org_district,
            taluka: values.taluka,
            org_pincode: values.org_pincode,
            mobnum: values.mobnum,
            email: values.email,
            firstnm: values.firstnm,
            lastnm: values.lastnm,
            marital_status: values.marital_status,
            gender: values.gender,
            birth_date: values.birth_date,
          });
          /*
          let formdata = new FormData()
          formdata.append("org_address", values.org_address)
          formdata.append("org_state", values.org_state)
          formdata.append("org_district", values.org_district)
          formdata.append("org_pincode", values.org_pincode)
          formdata.append("industry", "-")
          formdata.append("mobnum", values.mobnum)
          formdata.append("email", values.email)
          formdata.append("firstnm", values.firstnm)
          formdata.append("lastnm", values.lastnm)
          formdata.append("attested", 1)
          formdata.append("form_part", 1)
          */
          //setRegId(data['Developer Message'] ? data['Developer Message'] : "")

          setSubmitformOne(false);
          setOpen1(false);
          setNextStep(true);
          setShow(true);
          handleNext();
        } catch (err) {
          console.log(err);
          setOpen1(false);
          setNextStep(false);
          setSubmitformOne(false);
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: "Something went wrong.",
            //text: "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            setNextStep(false);
          });
        }
      }
    },
  });

  const formTwo = useFormik({
    initialValues: {
      highest_education_new: "",
      current_industry_new: "",
      current_salary_new: "",
      des_salary_new: "",
      job_role_new: "",
      self_opportunity: "",
      experience_new: "",
      stream_new: "",
      resume_required: "",
    },
    validationSchema: Yup.object().shape({
      highest_education_new: Yup.string().required(
        "Please select highest education"
      ),
      current_industry_new: Yup.string().required(
        "Please select current industry"
      ),
      job_role_new: Yup.string().required("Please select job role"),
      resume_required: Yup.string().required("Please select above field"),
    }),
    onSubmit: async (values, { resetForm }) => {
      let formdata = new FormData();
      //formdata.append("highest_education", values.highest_education_new)
      //formdata.append("stream", values.stream_new)
      formdata.append("skills", preffere.map((ele) => ele.value).toString());
      formdata.append("job_role", values.job_role_new);
      formdata.append("self_opportunity", values.self_opportunity);
      formdata.append("current_industry", values.current_industry_new);
      formdata.append("current_salary", values.current_salary_new);
      formdata.append("desired_salary", values.des_salary_new);
      formdata.append(
        "languages",
        languagesall.map((ele) => ele.value).toString()
      );
      formdata.append("experience", values.experience_new);
      formdata.append("wa_optin", "true");

      formdata.append("firstname", obj.firstnm);
      formdata.append("lastname", obj.lastnm);
      formdata.append("email", obj.email);
      formdata.append("mobnum", obj.mobnum);
      formdata.append(
        "date_of_birth",
        moment(obj.birth_date).format("YYYY-MM-DD")
      );
      formdata.append("gender", obj.gender);
      formdata.append("marital_status", obj.marital_status);
      formdata.append("state", obj.org_state ? obj.org_state : "");
      formdata.append("district", obj.org_district ? obj.org_district : "");
      formdata.append("taluka", obj.taluka ? obj.taluka : "");
      formdata.append("pincode", obj.org_pincode ? obj.org_pincode : "");
      formdata.append("resume", resumefile);
      formdata.append("resume_required", resumeCheck ? "1" : "0");
      // formdata.append("datasource", "VK_FORM");
      formdata.append(
        "datasource",
        query1 !== null && query1 !== "" ? `VKID_${query1}` : "VK_FORM"
      );
      // uat id need to change for prod
      formdata.append("VKID", query1);
      formdata.append("VKID_state", query2);
      formdata.append("VKID_district", query3);

      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/form/vakrangee`, formdata)
        .then((res) => {
          setIsLoading(false);
          res.data["Payment Status"]
            ? Swal.fire({
                icon: "error",
                title: "Error",
                text: "Duplicate Data",
                showConfirmButton: false,
                timer: 3000,
              })
            : Swal.fire({
                icon: "success",
                title: "",
                text: "Details Submitted Successfully",
                showConfirmButton: false,
                timer: 2000,
              })
                .then(() => {
                  setSubmitform(false);
                  resetForm();
                  setSelectedState("");
                })
                .then(
                  () => (
                    res.data["Payment URL"] &&
                      window.open(res.data["Payment URL"], "_blank"),
                    res.data["Payment URL"] && setPaymentLoading(true),
                    res.data["Payment URL"] && IntervalFunc(),
                    res.data["Payment URL"] &&
                      setTimeout(
                        () => (
                          console.log("count:", paymentLoading),
                          !paymentLoading && setPaymentLoading(false),
                          !paymentLoading && clearInterval(Interval),
                          !paymentLoading &&
                            Swal.fire({
                              icon: "error",
                              title: "Transaction Timeout",
                            })
                        ),
                        180000
                      )
                  )
                );
          setResumeCheck(false);
        })
        .catch((err) => {
          // console.log(err.response.data["Developer Message"]);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "",
            text: err.response.data["Developer Message"],
            // text: "Provide all required feilds",
            showConfirmButton: false,
            timer: 2000,
          });
        });
    },
  });

  // useEffect( ()=>{()
  //   if($('.select').length > 0) {
  //     $('.select').select2({
  //       minimumResultsForSearch: -1,
  //       width: '100%'
  //     });
  //   }
  // });
  return (
    <>
      <div>
        <div>
          <img
            style={{ width: "100%", height: "auto" }}
            src={cover1}
            className="logo-img"
          />
          <img
            style={{ width: "100%", height: "auto" }}
            src={cover1}
            className="logo-img1"
          />
        </div>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div className="SDiv">
            {show ? (
              <>
                <SBox2>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      m: 1,
                    }}
                  >
                    <STypography2 variant="body1">Registration</STypography2>

                    {matchsm ? (
                      <img
                        src={img1}
                        style={{ height: "53.13px", margin: "8px" }}
                        alt=""
                      />
                    ) : null}
                  </Box>

                  <Box
                    sx={{
                      maxWidth: "700px",
                      minWidth: "200px",
                      ml: "auto",
                      mr: "auto",
                      mb: 2,
                    }}
                  >
                    <Stepper activeStep={activeStep} sx={stepperStyle}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>
                              <STypography7 variant="body2">
                                {label}
                              </STypography7>
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Highest Education{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={educationOption}
                            onChange={(e) => {
                              formTwo.setFieldValue(
                                "highest_education_new",
                                e.value
                              );
                            }}
                            value={{
                              label: formTwo.values.highest_education_new,
                            }}
                            id="highest_education_new"
                            name="highest_education_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formTwo.touched.highest_education_new &&
                        formTwo.errors.highest_education_new ? (
                          <STypography5 variant="caption">
                            {formTwo.errors.highest_education_new}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">Stream</STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={streamOption}
                            onChange={(e) => {
                              formTwo.setFieldValue("stream_new", e.value);
                            }}
                            value={{ label: formTwo.values.stream_new }}
                            id="stream_new"
                            name="stream_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Current Industry{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={industryOption}
                            onChange={(e) => {
                              formTwo.setFieldValue(
                                "current_industry_new",
                                e.value
                              );
                            }}
                            value={{
                              label: formTwo.values.current_industry_new,
                            }}
                            id="current_industry_new"
                            name="current_industry_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formTwo.touched.current_industry_new &&
                        formTwo.errors.current_industry_new ? (
                          <STypography5 variant="caption">
                            {formTwo.errors.current_industry_new}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">Experience</STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={experienceOption}
                            onChange={(e) => {
                              formTwo.setFieldValue("experience_new", e.value);
                            }}
                            value={{ label: formTwo.values.experience_new }}
                            id="experience_new"
                            name="experience_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">Skills</STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder=""
                            options={skillsList}
                            isMulti
                            value={preffere}
                            onChange={(e) => {
                              if (e) {
                                setPreffere(e.map((item) => item));
                              } else {
                                setPreffere([]);
                              }
                            }}
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Current Salary
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={salaryList}
                            onChange={(e) => {
                              formTwo.setFieldValue(
                                "current_salary_new",
                                e.value
                              );
                            }}
                            value={{ label: formTwo.values.current_salary_new }}
                            id="current_salary_new"
                            name="current_salary_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Desired Salary
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={salaryList}
                            onChange={(e) => {
                              formTwo.setFieldValue("des_salary_new", e.value);
                            }}
                            value={{ label: formTwo.values.des_salary_new }}
                            id="des_salary_new"
                            name="des_salary_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Desired Job Role{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={jobRoleList}
                            onChange={(e) => {
                              formTwo.setFieldValue("job_role_new", e.value);
                            }}
                            value={{ label: formTwo.values.job_role_new }}
                            id="job_role_new"
                            name="job_role_new"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formTwo.touched.job_role_new &&
                        formTwo.errors.job_role_new ? (
                          <STypography5 variant="caption">
                            {formTwo.errors.job_role_new}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Languages Known
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            isMulti
                            placeholder=""
                            options={languageList}
                            value={languagesall}
                            onChange={(e) => {
                              if (e) {
                                setLanguagesall(e.map((item) => item));
                              } else {
                                setLanguagesall([]);
                              }
                            }}
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Interested in self employement opportunity?
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={opportunities}
                            onChange={(e) => {
                              formTwo.setFieldValue(
                                "self_opportunity",
                                e.value
                              );
                            }}
                            value={{ label: formTwo.values.self_opportunity }}
                            id="self_opportunity"
                            name="self_opportunity"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Resume Required{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={resumeOption}
                            onChange={(e) => {
                              setResume(e.label);
                              formTwo.setFieldValue("resume_required", e.value);
                              setResumeCheck(e.value === "1" ? true : false);
                            }}
                            value={{ label: resume }}
                            id="resume_required"
                            name="resume_required"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formTwo.touched.resume_required &&
                        formTwo.errors.resume_required ? (
                          <STypography5 variant="caption">
                            {formTwo.errors.resume_required}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>
                  </Grid>
                  <br />

                  {!resumeCheck && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <input
                        id="imgupload"
                        ref={ref}
                        style={{ display: "none" }}
                        type="file"
                        accept=".pdf, .doc, .docx"
                        onChange={handleFileUpload}
                      />
                      <label htmlFor="imgupload">
                        {resumefile === "" ? (
                          <SBox6>
                            <img src={img2} height={"60px"} alt="" />

                            <STypography8 variant="body1">
                              Choose File+
                            </STypography8>

                            <STypography9 variant="body1">
                              {
                                "Please upload files < 5mb and make sure format is either pdf or doc."
                              }
                            </STypography9>
                          </SBox6>
                        ) : (
                          <SBox6>
                            <img src={img4} height={"60px"} alt="" />

                            <STypography8 variant="body1">
                              File Uploaded
                            </STypography8>
                          </SBox6>
                        )}
                      </label>
                    </Box>
                  )}

                  <STypography10 variant="body1">
                    "If you already have a CV, please upload. If not, we will
                    prepare your Saksham powered CV and may share upon request."
                  </STypography10>

                  <Box sx={{ pt: 1, pb: 1, textAlign: "center" }}>
                    <STypography4 variant="p">
                      <input type="checkbox" name="checkbox" checked={true} />
                      &emsp;
                      <img
                        src={watsapp}
                        style={{ height: "30px", width: "30px" }}
                      />
                      &nbsp;I give the consent to share my details and I allow
                      to send me further details and updates over WhatsApp.
                    </STypography4>
                  </Box>

                  <Box sx={{ p: 1, mt: 1 }}>
                    <SButton1 onClick={formTwo.handleSubmit}>
                      <STypography6 variant="body1">Submit</STypography6>
                    </SButton1>

                    <SButton2
                      onClick={() => {
                        setShow(false);
                        setSubmitform(false);
                        formTwo.handleReset();
                        //formOne.handleReset();
                        setNextStep(false);
                        handleReset();
                        setResumeCheck(false);
                        setResume("");
                        setResumeFile("");
                      }}
                    >
                      <STypography6 variant="body1">Cancel</STypography6>
                    </SButton2>
                  </Box>
                </SBox2>
              </>
            ) : (
              <>
                <SBox2>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      m: 1,
                    }}
                  >
                    <STypography2 variant="body1">Registration</STypography2>

                    {matchsm ? (
                      <img
                        src={img1}
                        style={{ height: "53.13px", margin: "8px" }}
                        alt=""
                      />
                    ) : null}
                  </Box>

                  <Box
                    sx={{
                      maxWidth: "700px",
                      minWidth: "200px",
                      ml: "auto",
                      mr: "auto",
                      mb: 2,
                    }}
                  >
                    <Stepper activeStep={activeStep} sx={stepperStyle}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>
                              <STypography7 variant="body2">
                                {label}
                              </STypography7>
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          First Name{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="text"
                          fullWidth
                          id="firstnm"
                          name="firstnm"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.firstnm}
                        />

                        {formOne.touched.firstnm && formOne.errors.firstnm ? (
                          <STypography5 variant="caption">
                            {formOne.errors.firstnm}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Last Name{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="text"
                          fullWidth
                          id="lastnm"
                          name="lastnm"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.lastnm}
                        />

                        {formOne.touched.lastnm && formOne.errors.lastnm ? (
                          <STypography5 variant="caption">
                            {formOne.errors.lastnm}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">Email</STypography4>

                        <TextField
                          disabled={nextStep}
                          type="email"
                          fullWidth
                          id="email"
                          name="email"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.email}
                        />

                        {formOne.touched.email && formOne.errors.email ? (
                          <STypography5 variant="caption">
                            {formOne.errors.email}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Mobile Number{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="tel"
                          fullWidth
                          id="mobnum"
                          name="mobnum"
                          size="small"
                          inputProps={{
                            pattern:
                              "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                            maxLength: "10",
                            minLength: "10",
                          }}
                          onChange={formOne.handleChange}
                          value={formOne.values.mobnum}
                        />

                        {formOne.touched.mobnum && formOne.errors.mobnum ? (
                          <STypography5 variant="caption">
                            {formOne.errors.mobnum}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Date of Birth{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          disabled={nextStep}
                          type="date"
                          fullWidth
                          id="birth_date"
                          name="birth_date"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.birth_date}
                        />

                        {formOne.touched.birth_date &&
                        formOne.errors.birth_date ? (
                          <STypography5 variant="caption">
                            {formOne.errors.birth_date}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Gender{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            isDisabled={nextStep}
                            placeholder="Select"
                            options={GenderL}
                            onChange={(e) => {
                              formOne.setFieldValue("gender", e.value);
                            }}
                            value={{ label: formOne.values.gender }}
                            id="gender"
                            name="gender"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formOne.touched.gender && formOne.errors.gender ? (
                          <STypography5 variant="caption">
                            {formOne.errors.gender}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Marital Status
                        </STypography4>
                        <Select
                          isDisabled={nextStep}
                          placeholder="Select"
                          options={maritalstatusList}
                          onChange={(e) => {
                            formOne.setFieldValue("marital_status", e.value);
                          }}
                          value={{ label: formOne.values.marital_status }}
                          id="marital_status"
                          name="marital_status"
                          styles={{
                            placeholder: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: "Inter",
                              color: "rgba(0, 0, 0, 0.3)",
                            }),
                            menu: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: "Inter",
                              fontSize: "15px",
                              textAlign: "left",
                            }),
                          }}
                        />
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          State{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            isDisabled={nextStep}
                            placeholder="Select"
                            options={State}
                            onChange={(e) => {
                              formOne.setFieldValue("org_state", e.value);
                              setSelectedState(e.value);
                            }}
                            value={{ label: formOne.values.org_state }}
                            id="org_state"
                            name="org_state"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formOne.touched.org_state &&
                        formOne.errors.org_state ? (
                          <STypography5 variant="caption">
                            {formOne.errors.org_state}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          District{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            isDisabled={nextStep}
                            placeholder="Select"
                            options={District}
                            onChange={(e) => {
                              formOne.setFieldValue("org_district", e.value);
                            }}
                            value={{ label: formOne.values.org_district }}
                            id="org_district"
                            name="org_district"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formOne.touched.org_district &&
                        formOne.errors.org_district ? (
                          <STypography5 variant="caption">
                            {formOne.errors.org_district}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">Taluka</STypography4>

                        <TextField
                          disabled={nextStep}
                          type="text"
                          fullWidth
                          id="taluka"
                          name="taluka"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.taluka}
                        />

                        {formOne.touched.taluka && formOne.errors.taluka ? (
                          <STypography5 variant="caption">
                            {formOne.errors.taluka}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">Pin Code</STypography4>

                        <TextField
                          disabled={nextStep}
                          type="tel"
                          inputProps={{
                            pattern:
                              "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                            maxLength: "6",
                            minLength: "6",
                          }}
                          fullWidth
                          id="org_pincode"
                          name="org_pincode"
                          size="small"
                          onChange={formOne.handleChange}
                          value={formOne.values.org_pincode}
                        />

                        {formOne.touched.org_pincode &&
                        formOne.errors.org_pincode ? (
                          <STypography5 variant="caption">
                            {formOne.errors.org_pincode}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>
                  </Grid>

                  <Box sx={{ p: 1, mt: 1 }}>
                    <SButton1
                      onClick={
                        nextStep
                          ? () => {
                              setShow(true);
                            }
                          : () => {
                              setSubmitformOne(true);
                              formOne.handleSubmit();
                            }
                      }

                      //onClick={() => { setShow(true); handleNext() }}
                    >
                      <STypography6 variant="body1">
                        Proceed to Next Step <ArrowForwardIcon />
                      </STypography6>
                    </SButton1>

                    <SButton2
                      onClick={() => {
                        formOne.handleReset();
                        setSubmitformOne(false);
                        setNextStep(false);
                        handleReset();
                      }}
                    >
                      <STypography6 variant="body1">Cancel</STypography6>
                    </SButton2>
                  </Box>
                </SBox2>
              </>
            )}
          </div>
        </Box>
      </div>
      <Modal
        show={paymentLoading}
        onHide={() => {
          setPaymentLoading(false);
        }}
        centered
      >
        <Modal.Body>
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                m: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // marginTop: "100px",
              }}
            >
              {/* Loading...... */}
              Payment in progress
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <LoadingSpinner />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={
            matchsm
              ? { textAlign: "center", width: "350px" }
              : { textAlign: "center", maxWidth: "350px" }
          }
        >
          <Box
            sx={{
              width: "300px",
              m: 2,
              ml: "auto",
              mr: "auto",
              p: 1,
              border: "3px dashed #A7A7A7",
              minHeight: "300px",
            }}
          >
            <img src={img4} height={"70px"} alt="" />
            <STypography11 variant="body2">
              Form submited successfully
            </STypography11>
            <hr />

            <STypography4 variant="body1">
              Thank you for registering with us!
            </STypography4>

            <SButton3
              onClick={() => {
                handleClose();
                window.location.reload();
                setObj({
                  org_address: "",
                  org_state: "",
                  org_district: "",
                  taluka: "",
                  org_pincode: "",
                  mobnum: "",
                  email: "",
                  firstnm: "",
                  lastnm: "",
                  marital_status: "",
                  gender: "",
                  birth_date: "",
                });
              }}
            >
              <STypography6 variant="body1">
                Go back to form <ArrowForwardIcon />
              </STypography6>
            </SButton3>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open1}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={
            matchsm
              ? { textAlign: "center", width: "350px" }
              : { textAlign: "center", maxWidth: "350px" }
          }
        >
          <Box
            sx={{
              width: "300px",
              m: 2,
              ml: "auto",
              mr: "auto",
              p: 1,
              border: "3px dashed #A7A7A7",
              minHeight: "300px",
            }}
          >
            <img src={img4} height={"70px"} alt="" />
            <STypography11 variant="body2">
              Details saved successfully
            </STypography11>
            <hr />

            <STypography4 variant="body1">
              “You are just one step away to start your journey”
            </STypography4>

            <STypography9 variant="body1">
              Please do not close the window or go back
            </STypography9>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default HorizontalForm;
